import { getDistroListGroupMembers, isDistroListEmail } from "../lib/distroListFunctions";
import { getObjectEmail } from "../lib/objectFunctions";
import { pluralize } from "../lib/stringFunctions";
import { DexieDistroList, DexieDistroListMember, DexieGroup } from "./db";
import { RSVP_STATUS } from "./googleCalendarService";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "./typeGuards";

export function isAttendeeResource<T extends VimcalAttendee | Attendee>(attendee: T) {
  return (getObjectEmail(attendee) ?? "").includes("resource.calendar");
}

export function filterOutResourceAttendees<T extends VimcalAttendee | Attendee>(attendeeList: T[]) {
  if (isEmptyArrayOrFalsey(attendeeList)) {
    return [];
  }

  return attendeeList.filter((a) => !isAttendeeResource(a));
}

/**
 * Convert a distro list member from our Dexie DB into an Attendee that the frontend can use.
 */
export function distroListMemberToAttendee(member: DexieDistroListMember): Attendee {
  return {
    displayName: member.displayName ?? "",
    email: getObjectEmail(member) ?? "",
    responseStatus: "none",
  };
}

interface GetAllAttendeesOptions {
  attendees: Attendee[]
  distroListDictionary?: Record<string, DexieDistroList | DexieGroup>
}

/**
 * Take a list of attendees and add in any members of included distribution lists.
 * Also filter out attendees that are resources.
 */
export function getAllAttendees({ attendees, distroListDictionary }: GetAllAttendeesOptions) {
  const inputAttendees = filterOutResourceAttendees(attendees);
  if (isEmptyObjectOrFalsey(distroListDictionary)) {
    return inputAttendees;
  }
  let allAttendees = inputAttendees.filter(attendee => !isDistroListEmail({
    email: getObjectEmail(attendee) ?? "",
    distroListDictionary,
  }));
  inputAttendees.forEach(attendee => {
    const email = getObjectEmail(attendee) ?? "";
    if (isDistroListEmail({email, distroListDictionary})) {
      const distroListMembers = getDistroListGroupMembers({
        email,
        distroListDictionary,
      });
      const distroListAttendees = filterOutResourceAttendees(distroListMembers.map(distroListMemberToAttendee));
      allAttendees = allAttendees.concat(distroListAttendees);
    }
  });

  // need to remove duplicates, prioritize the ones with actually attendence information
  const RESPONSE_STATUS_NONE = "none";
  const priority = {
    [RSVP_STATUS.ATTENDING]: 4,
    [RSVP_STATUS.MAYBE]: 3,
    [RSVP_STATUS.DECLINED]: 2,
    [RSVP_STATUS.NEEDS_ACTION]: 1,
    [RESPONSE_STATUS_NONE]: 0,
  } as const;
  const uniqueAttendees: Attendee[] = [];
  const map = new Map<string, Attendee>();
  allAttendees.forEach(attendee => {
    const responseStatus = attendee.responseStatus || RESPONSE_STATUS_NONE;
    const email = getObjectEmail(attendee) ?? "";
    const existingAttendee = map.get(email);
    if (!existingAttendee || priority[responseStatus] > priority[existingAttendee.responseStatus]) {
      map.set(email, attendee);
    }
  });
  map.forEach(value => uniqueAttendees.push(value));
  return uniqueAttendees;
}

/**
 * Summarize the total number of attendees as well as the counts of each RSVP status.
 */
export function getGuestAttendanceCounts({ attendees, distroListDictionary }: GetAllAttendeesOptions) {
  const filteredAttendees = getAllAttendees({ attendees, distroListDictionary });

  const acceptedCount = filteredAttendees.filter(
    (item) => item.responseStatus === RSVP_STATUS.ATTENDING,
  ).length;
  const tentativeCount = filteredAttendees.filter(
    (item) => item.responseStatus === RSVP_STATUS.MAYBE,
  ).length;
  const declinedCount = filteredAttendees.filter(
    (item) => item.responseStatus === RSVP_STATUS.DECLINED,
  ).length;

  // the rest falls into here
  const needsActionResponseCount = filteredAttendees.filter(
    (item) => ![RSVP_STATUS.DECLINED, RSVP_STATUS.ATTENDING, RSVP_STATUS.MAYBE].includes(item.responseStatus),
  ).length;

  const guestCount = String(filteredAttendees.length) + " " + pluralize(filteredAttendees.length, "guest");

  const needsActionResponseString = needsActionResponseCount > 0
    ? ", " + String(needsActionResponseCount) + " awaiting" : "";

  return {
    guestCount,
    acceptedCount,
    tentativeCount,
    declinedCount,
    needsActionResponseCount,
    needsActionResponseString,
  };
}
