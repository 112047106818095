import Dexie, { EntityTable } from "dexie";
import {
  handleError,
  getStartOfDayUTC,
  getEndOfDayUTC,
} from "./commonUsefulFunctions";
import { getCurrentUserEmail } from "../lib/localData";
import {
  getEndTimeUTC,
  getStartTimeUTC,
  isAllDayEvent,
} from "../lib/eventFunctions";
import {
  getEventMasterEventID,
  getEventUserCalendarID,
} from "./eventResourceAccessors";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "./typeGuards";
import { getObjectEmail } from "../lib/objectFunctions";
import { removeDuplicatesFromArray } from "../lib/arrayFunctions";

interface DexieBuilding {
  buildingId: string[]
  buildingName: string[]
  buildingObject: Building
  domainBuildingId: string
  fullName: string
}

interface DexieConferenceRoom {
  buildingId: string
  capacity: number
  features?: OutlookRoom["features"]
  fullName: string
  resourceEmail: string
  resourceName: string[]
  roomsObject: Room
  userVisibilityDescription: string
}

interface DexieContact {
  accountPrimaryEmail: string
  email: string
  firstName: string
  fullName: string[]
  lastName: string
  name: string
  primary: boolean
  userEmail: string
}

export interface DexieDistroListMember {
  providerId: string
  displayName: string | null
  email: string
}

export interface DexieDistroList {
  providerId: string
  name: string | null
  email: string
  description: string | null
  members: DexieDistroListMember[]
  subgroups: DexieDistroListMember[]
}

interface DexieDomainUser {
  accountPrimaryEmail: string
  email: string
  firstName: string
  fullName: string[]
  lastName: string
  name: string
  primary: boolean
  userEmail: string
}

interface DexieEvent {
  user_event_id: string
  calendarId: string
  date: number
  summary: string[]
  attendees: string[]
  location: string[]
  event: VimcalEvent
}

// TODO VIM-3984: Update Google groups to use same schema as distro lists, and deprecate
// this table.
export interface DexieGroup {
  googleID: string
  email: string
  name: string
  description: string
  aliases: string | null
  nonEditableAliases: string[]
  googleGroupMembers: {
    email: string
    google_id: string
    member_type: string
    role: string
    status: string
  }[]
  directMembersCount: number
}

interface DexieHashKey {
  key: number
}

type DexieDBSchema = Dexie & {
  buildings: EntityTable<DexieBuilding, "domainBuildingId">
  conferenceRooms: EntityTable<DexieConferenceRoom, "resourceEmail">
  contacts: EntityTable<DexieContact, "email">
  distroLists: EntityTable<DexieDistroList, "providerId">
  domainUsers: EntityTable<DexieDomainUser, "email">
  events: EntityTable<DexieEvent, "user_event_id">
  groups: EntityTable<DexieGroup, "googleID">
  hashKey: EntityTable<DexieHashKey, "key">
}

class DexieDB {
  "use strict";
  emailDB: Record<string, DexieDBSchema>;

  constructor() {
    this.emailDB = {};
  }

  resetEmailDBIndex() {
    this.emailDB = {};
  }

  fetch(inputEmail?: string): DexieDBSchema | void {
    const email = inputEmail || getCurrentUserEmail() || "default_user";

    if (this.emailDB[email] && this.emailDB[email].isOpen()) {
      return this.emailDB[email];
    } else {
      try {
        const newDB = new Dexie(email) as DexieDBSchema;
        newDB.version(1).stores({
          events:
            "&user_event_id, calendarId, date, *summary, *attendees, *location, event",
          contacts:
            "&email, *fullName, name, updated, firstName, lastName, primary, accountPrimaryEmail, userEmail",
          // TODO: Fix this. The schema we actually store domainUsers in is identical to contacts.
          domainUsers: "&email, *fullName, name, updated",
          conferenceRooms:
            "&resourceEmail, *resourceName, userVisibilityDescription, roomsObject, buildingId, fullName, capacity",
          buildings:
            "&domainBuildingId, *buildingId, *buildingName, buildingObject, fullName",
          hashKey: "&key",
        });
        newDB.version(2).stores({
          groups:
            "&googleID, email, name, description, aliases, *nonEditableAliases, *googleGroupMembers, directMembersCount",
        });
        newDB.version(3).stores({
          conferenceRooms:
            "&resourceEmail, *resourceName, userVisibilityDescription, roomsObject, buildingId, fullName, capacity, features",
        });
        newDB.version(4).stores({
          distroLists: "&providerId, email, name, description, *members, *subgroups",
        });

        this.emailDB[email] = newDB;

        return this.emailDB[email];
      } catch (error) {
        handleError(error);
      }
    }
  }

  async singleAccountLogout(email: string) {
    if (!email) {
      return;
    }

    try {
      const stores = await Dexie.getDatabaseNames();
      if (stores.includes(email)) {
        await this.wipeOutAccountStore(email);
      }
    } catch (error) {
      handleError(error);
    }
  }

  async logOut(dbsToDelete: User[] = []) {
    const stores = await Dexie.getDatabaseNames();
    let dexieDBs: (string | null)[] = [];
    if (!isEmptyObjectOrFalsey(this.emailDB)) {
      dexieDBs = dexieDBs.concat(Object.keys(this.emailDB));
    }

    if (dbsToDelete?.length > 0) {
      dexieDBs = dexieDBs.concat(dbsToDelete.map((d) => getObjectEmail(d) ?? null));
    }

    dexieDBs = removeDuplicatesFromArray(dexieDBs);

    for (const email of dexieDBs) {
      if (email && stores.includes(email)) {
        await this.wipeOutAccountStore(email);
      }
    }
  }

  async wipeOutAccountStore(email: string) {
    const db = this.fetch(email);
    if (!db) {
      return;
    }

    try {
      await db.delete();
    } catch (error) {
      handleError(error);
    }
  }

  getTimeMaxAndMinString({ timeMin, timeMax }: { timeMin: Date, timeMax: Date }) {
    const timeMinUTC = timeMin.toISOString();
    const timeMaxUTC = timeMax.toISOString();

    const allDayTimeMinUTC = getStartOfDayUTC(timeMin).toISOString();
    const allDayTimeMaxUTC = getEndOfDayUTC(timeMax).toISOString();
    return {
      timeMinUTC,
      timeMaxUTC,
      allDayTimeMinUTC,
      allDayTimeMaxUTC,
    };
  }

  /// returns db event object with is {event, user_event_id, ...}
  getEventsFromDate({ email, timeMin, timeMax }: {
    email: string,
    timeMin: Date,
    timeMax: Date,
  }) {
    if (!timeMin || !timeMax) {
      return;
    }

    const db = this.fetch(email);
    if (!db) {
      return;
    }
    const { timeMinUTC, timeMaxUTC, allDayTimeMinUTC, allDayTimeMaxUTC } =
      this.getTimeMaxAndMinString({ timeMin, timeMax });

    const eventsWithinDate = db.events
      .filter((dbEvent) => {
        if (!dbEvent?.event) {
          return false;
        }
        const { event } = dbEvent;
        if (isAllDayEvent(event)) {
          return (
            getStartTimeUTC(event) <= allDayTimeMaxUTC &&
            getEndTimeUTC(event) >= allDayTimeMinUTC
          );
        }
        return (
          getStartTimeUTC(event) <= timeMaxUTC &&
          getEndTimeUTC(event) >= timeMinUTC
        );
      })
      .toArray();

    return eventsWithinDate;
  }

  async deleteRecurringEvents({
    email,
    masterEventID,
    originalEvent, // for deleting this and following
  }: {
    email: string
    masterEventID: string
    originalEvent: VimcalEvent
  }) {
    if (!email || !masterEventID) {
      return;
    }
    const db = this.fetch(email);

    if (!db) {
      return;
    }

    const originalEventStartTimeUTC = getStartTimeUTC(originalEvent);
    if (!originalEventStartTimeUTC) {
      // delete all instances of this recuring event
      try {
        const matchingEventIDs = await db.events
          .filter((dBEvent) => {
            if (!dBEvent?.event) {
              return false;
            }
            const { event } = dBEvent;
            return getEventMasterEventID(event) === masterEventID;
          })
          .primaryKeys(); // Use .primaryKeys() to get an array of the primary keys
        return db.events.bulkDelete(matchingEventIDs);
      } catch (error) {
        handleError(error);
      }
    }

    // for this and following
    try {
      const matchingEventIDs = await db.events
        .filter((dBEvent) => {
          if (!dBEvent?.event) {
            return false;
          }
          const { event } = dBEvent;
          return (
            getEventMasterEventID(event) === masterEventID &&
            getStartTimeUTC(event) >= originalEventStartTimeUTC
          );
        })
        .primaryKeys(); // Use .primaryKeys() to get an array of the primary keys

      return db.events.bulkDelete(matchingEventIDs);
    } catch (error) {
      handleError(error);
    }
  }

  async wipeOutEventsWithMatchingUserCalendarIDs({
    email,
    timeMin,
    timeMax,
    userCalendarIDs,
  }: {
    email: string
    timeMin: Date
    timeMax: Date
    userCalendarIDs: string[]
  }) {
    if (!timeMin || !timeMax || isEmptyArrayOrFalsey(userCalendarIDs)) {
      return;
    }
    const db = this.fetch(email);

    if (!db) {
      return;
    }

    try {
      const { timeMinUTC, timeMaxUTC, allDayTimeMinUTC, allDayTimeMaxUTC } =
        this.getTimeMaxAndMinString({ timeMin, timeMax });

      const matchingEventIDs = await db.events
        .filter((dbEvent) => {
          if (!dbEvent?.event) {
            return false;
          }
          const { event } = dbEvent;
          if (!userCalendarIDs.includes(getEventUserCalendarID(event))) {
            return false;
          }
          if (isAllDayEvent(event)) {
            return (
              getStartTimeUTC(event) <= allDayTimeMaxUTC &&
              getEndTimeUTC(event) >= allDayTimeMinUTC
            );
          }
          return (
            getStartTimeUTC(event) <= timeMaxUTC &&
            getEndTimeUTC(event) >= timeMinUTC
          );
        })
        .primaryKeys(); // Use .primaryKeys() to get an array of the primary keys

      return db.events.bulkDelete(matchingEventIDs);
    } catch (error) {
      handleError(error);
    }
  }

  async wipeOutEventsOnRefresh({ email, timeMin, timeMax }: {
    email: string,
    timeMin: Date,
    timeMax: Date,
  }) {
    if (!timeMin || !timeMax) {
      return;
    }
    const db = this.fetch(email);

    if (!db) {
      return;
    }

    try {
      const { timeMinUTC, timeMaxUTC, allDayTimeMinUTC, allDayTimeMaxUTC } =
        this.getTimeMaxAndMinString({ timeMin, timeMax });
      const matchingEventIDs = await db.events
        .filter((dbEvent) => {
          if (!dbEvent?.event) {
            return false;
          }
          const { event } = dbEvent;
          if (isAllDayEvent(event)) {
            return (
              getStartTimeUTC(event) <= allDayTimeMaxUTC &&
              getEndTimeUTC(event) >= allDayTimeMinUTC
            );
          }
          return (
            getStartTimeUTC(event) <= timeMaxUTC &&
            getEndTimeUTC(event) >= timeMinUTC
          );
        })
        .primaryKeys();

      return db.events.bulkDelete(matchingEventIDs);
    } catch (error) {
      handleError(error);
    }
  }

  wipeOutConferenceRoomsAndBuildings(email: string) {
    const db = this.fetch(email);

    if (!db) {
      return;
    }

    try {
      return [db.conferenceRooms.clear(), db.buildings.clear()];
    } catch (error) {
      handleError(error);
    }
  }
}

const db = new DexieDB();
export default db;
