import { getSearchParams, isElectron } from "../services/commonUsefulFunctions";
import packageJson from "../../../package.json";
import { usePermissionsStore } from "../services/stores/permissionsStore";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import { getAccountState } from "./stateManagementFunctions";
import {
  ACCOUNT_STATE_FREEMIUM,
  ACCOUNT_STATE_PREPAID,
} from "./vimcalVariables";
import { useFeatureFlags } from "../services/stores/appFunctionality";
import { getMasterAccountID, getUserEmail } from "./userFunctions";
import { ROLES } from "../components/onboarding/sharedFunctions";
import { isUserMaestroUser } from "../services/maestroFunctions";
import { isOutlookUser } from "./outlookFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { isLocal } from "../services/devFunctions";
import { isGoogleUser } from "../services/appFunctions";
import { isSameEmail } from "./stringFunctions";

export function stripeCustomerPortalFlag() {
  return true;
}

/**
 * @returns {boolean}
 */
export function isInternalTeamUserEmail(email) {
  if (!email) {
    return false;
  }
  return (
    email.includes("@vimcal.com") ||
    email.includes("@weveapp.com") ||
    email.includes("@piedpepper.co") ||
    email.includes("vimcalzhao")
  );
}

export function isInternalTeamUser(currentUser) {
  if (isEmptyObjectOrFalsey(currentUser)) {
    return false;
  }

  const email = getUserEmail(currentUser);
  if (!email) {
    return false;
  }
  return isInternalTeamUserEmail(email);
}

export function isBetaSelfServeUser() {
  const urlParams = getSearchParams();
  if (!urlParams || !urlParams.get) {
    return false;
  }
  return urlParams?.get && urlParams.get("ref") === "b";
}

export function shouldHideMenuBarInterval() {
  return packageJson?.version >= "1.0.55";
}

export function displayFocusMode(currentUser) {
  return isInternalTeamUser(currentUser);
}

export function showDesignFlag(currentUser) {
  if (!currentUser?.email) {
    return false;
  }

  return (
    currentUser?.email === "dillon@vimcal.com" ||
    currentUser?.email === "mike@vimcal.com" ||
    currentUser?.email === "john@vimcal.com"
  );
}

export function isForRelease(currentUser) {
  return currentUser?.email === "richard@piedpepper.co";
}

export function byPassOnboardMaestro(currentUser) {
  return currentUser?.email === "mike@vimcal.com";
}

export function isDebuggingUserMikeVimcal(currentUser) {
  return currentUser?.email === "mike@vimcal.com";
}

export function isDebuggingMasterMike(masterAccount) {
  return (
    masterAccount?.stripe_email === "mike@vimcal.com" ||
    masterAccount?.stripe_email === "mchlzhao@gmail.com"
  );
}

export function skipCheckForSlotsAnimation(currentUser) {
  return false;
  // return isDebuggingUserMikeVimcal(currentUser);
}

export function skipRerouteTrialOver(currentUser) {
  return (
    isDebuggingUserMikeVimcal(currentUser) ||
    currentUser?.email === "seamus@vimcal.com"
  );
}

export function shouldShowBottomTrialIsLeft(masterAccount) {
  if (isEmptyObjectOrFalsey(masterAccount) || !masterAccount?.id) {
    return false;
  }

  if (isDebuggingMasterMike(masterAccount)) {
    // for self testing
    return true;
  }

  const { id } = masterAccount;

  if (id < 23345) {
    // 23345 is the highest id in master account as of Nov 14 12pm EST
    return false;
  }

  return id % 2 === 0;
}

export function shouldDisplayReferralInCorner({ masterAccount }) {
  if (isEmptyObjectOrFalsey(masterAccount) || !masterAccount?.id) {
    return false;
  }

  const { id } = masterAccount;

  // 25397 is the highest master account id as of Jan 0th 4:30pm ET
  return id % 2 === 0;
}

export function showCustomizeUsername() {
  return true;
}

export function isForcingPrepaidUserToPersonalOnboarding() {
  return false;
}

export function isSelfServeOpen() {
  return true;
  if (isElectron()) {
    return true;
  }

  if (
    getAccountState(useMasterAccount.getState()?.masterAccount) ===
    ACCOUNT_STATE_FREEMIUM
  ) {
    return true;
  }

  const permissionState = usePermissionsStore.getState();
  if (permissionState?.hasSelfServeAccess) {
    return true;
  }

  const masterAccount = useMasterAccount.getState();
  if (
    !isForcingPrepaidUserToPersonalOnboarding() &&
    getAccountState(masterAccount?.masterAccount) === ACCOUNT_STATE_PREPAID
  ) {
    return true;
  }

  return false;
}

export function isEmailAVimcalDomain(email) {
  if (!email) {
    return false;
  }

  const allowedDomains = ["vimcal.com", "piedpepper.co", "weveapp.com"];
  return allowedDomains.some((substring) => email.includes(substring));
}

export function isMikeVimcal() {
  return (
    useMasterAccount.getState()?.masterAccount?.stripe_email ===
    "mike@vimcal.com"
  );
}

export function isTestingOutlookDiffEvents(userEmail) {
  return false;
}

export function isShortWindowFetchUser(user) {
  if (!user?.email) {
    return false;
  }
  return user.email.includes("bcg.com");
}

export function shouldShowFindTimeEventForm(user) {
  const featureFlagStates = useFeatureFlags.getState();
  if (featureFlagStates?.shouldShowFindTime) {
    return true;
  }
  return isInternalTeamUser(user);
}

export function isOpenToConstantSync(masterAccount) {
  return true; // always sync now
  const id = parseInt(getMasterAccountID(masterAccount));
  if (!id || isNaN(id)) {
    return null;
  }
  // open 30% at a time
  return id % 3 === 0;
}

export function isPrintIndexDBError(user) {
  const email = getUserEmail(user);
  if (!email) {
    return false;
  }
  if (isLocal()) {
    return true;
  }
  return isInternalTeamUser(user) || email.includes("vedika");
}

/**
 * @returns {boolean | undefined}
 */
export function isBCGUser(user) {
  return getUserEmail(user)?.includes("bcg.com");
}

export function isHubSpotUser(user) {
  return getUserEmail(user)?.includes("hubspot.com");
}

// some users prefer the title to be empty by default
export function allowDefaultToEmptySlotsTitle(user) {
  return isBCGUser(user) || isInternalTeamUser(user);
}

export function isSyncingCategories(user) {
  return isOutlookUser(user); // giving categories to every outlook user
}

export function hasGroupSpreadsheetAccess({ user, masterAccount }) {
  return isInternalTeamUser(user) || isUserMaestroUser(masterAccount);
}

export function shouldSeeMandatoryOnboarding({
  role,
}) {
  return role === ROLES.EXECUTIVE_ASSISTANT; // ran experiment and found that conversion rate for forced is 31% while not forced is 37%
}

export function shouldGetOutlookPreviewEvents(user) {
  return isOutlookUser(user);
  if (!isOutlookUser(user)) {
    return false;
  }
  return isInternalTeamUser(user) || isBCGUser(user);
}

export function isAviso(user) {
  return getUserEmail(user)?.includes("@aviso.ca");
}

export function canEditZoomSettings(user) {
  return true;
  return isInternalTeamUser(user) || isBCGUser(user);
}

export function canUseMagicLinks(user) {
  return isInternalTeamUser(user);
}

export function shouldDisplayColorLabel({ user }) {
  return isGoogleUser(user);
}

export function hasPermissionToViewDistroList(user) {
  return isInternalTeamUser(user) || isHubSpotUser(user);
}

export function isWBUser(user) {
  return isWBEmail(getUserEmail(user));
}

export function isWBEmail(email) {
  return email?.includes("wbd.com");
}
export function isBoseEmail(email) {
  return email?.includes("bose.com");
}

export function isBoseUser(user) {
  return isBoseEmail(getUserEmail(user));
}

export function shouldHideAIFeatures(user) {
  return isWBUser(user);
}

export const DEMO_ACCOUNT_EMAIL = "donna@piedpepper.co";
export function isDemoAccount(masterAccount) {
  return isSameEmail(masterAccount?.stripe_email, DEMO_ACCOUNT_EMAIL);
}

export function isShowingMonthlyMetrics() {
  return false;
}

export function shouldShowMetricsLineGraph(user) {
  return true;
  return isInternalTeamUser(user);
}

export function shouldShowListOfActions({ masterAccount }) {
  return isUserMaestroUser(masterAccount);
}

export function shouldShowEmailReminders(user) {
  return true;
  return isInternalTeamUser(user);
}

export function hasOutlookConferenceRoomFeatureFlag(user) {
  return isWBUser(user) || isInternalTeamUser(user);
}

export function preventHoverExpandedEvent({user, masterAccount}) {
  if (isInternalTeamUser(user)) {
    return false;
  }
  return isUserMaestroUser(masterAccount);
}

export function shouldDefaultFocusToTitleForEventForm({user, masterAccount}) {
  try {
    if (isInternalTeamUser(user) || getUserEmail(user)?.includes("potanski.com")) {
      return false;
    }
    if (isUserMaestroUser(masterAccount)) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

export function shouldShowSlotsTextFormat(user) {
  return true;
  return isInternalTeamUser(user);
}

export function isInActivePilot(user) {
  return isInternalTeamUser(user) || getUserEmail(user)?.includes("@drata.com");
}

export function shouldOpen429Retry(user) {
  return isInternalTeamUser(user);
}
