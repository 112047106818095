import { getStoredAttribution } from "../lib/attributionFunctions";

export const UTM_SOURCE = {
  TYPEFORM: "typeform",
};
// https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export function getQueryParamFromString(inputString) {
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  return new Proxy(new URLSearchParams(inputString), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
}

export function getQueryParam() {
  // TODO: the way getQueryParam works seems kind of dangerous. We're creating a proxy for URLSearchParams and overriding the default behavior, which introduces unnecessary risk and confusion
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  return getQueryParamFromString(window.location.search);
}

export function getAuthCode() {
  const params = getQueryParam();
  return params?.code;
}

export function getUnauthorizedPaymentUserFromQueryParam() {
  const params = getQueryParam();
  return params?.email;
}

export function getInstallAttribution() {
  const storedAttribution = getStoredAttribution();
  const params = getQueryParam();
  const signUpAttribution = getSignupAttribution();
  const promoDiscount = params?.d;
  return storedAttribution ?? signUpAttribution ?? promoDiscount ?? "web_none"; // attribution should be calendar.vimcal.com/?a="tik_token"
}

export function getAttributionParam() {
  const params = getQueryParam();
  return params?.a;
}

export function getSSOLoginTokenQueryParam() {
  const params = getQueryParam();
  return params?.sso_login_token;
}

export function getSignupAttribution() {
  const defaultAttribution = getAttributionParam();
  const paramString = getCompleteParamString();

  if (paramString.includes("google")) {
    // sample param from google ads: ?utm_source=google&utm_medium=cpc&utm_term={keyword}&utm_campaign={campaignid}&utm_content={matchtype}
    return paramString;
  }

  return defaultAttribution;
}

export function getCompleteParamString() {
  const params = new URLSearchParams(window.location.search);
  return params?.toString() ?? "";
}

// the url is a self serve link, so we open self serve
export function isOpenSelfServeLink() {
  return window?.location?.href?.includes("aloha");
}

export function getUserCodes() {
  const queryParams = getQueryParam();
  if (!queryParams) {
    return {
      referral: "",
      utmSource: "",
      promo: "",
      affiliate: "",
      attribution: "",
      utmMedium: "",
      utmCampaign: "",
      from: "",
    };
  }

  const referral = queryParams?.referral_code;
  const utmSource = queryParams?.utm_source;
  const promo = queryParams?.d;
  const attribution = queryParams?.a;
  const utmMedium = queryParams?.utm_medium;
  const utmCampaign = queryParams?.utm_campaign;
  const affiliate = queryParams?.via;
  const from = queryParams?.from;

  return {
    referral,
    utmSource,
    promo,
    affiliate,
    attribution:
      attribution ??
      promo ??
      utmSource ??
      affiliate ??
      utmMedium ??
      utmCampaign ??
      referral ??
      "",
    utmMedium,
    utmCampaign,
    from,
  };
}

export function getRerouteForAddAccountOnboarding() {
  return "&reroute=onboarding-add-account";
}

export function isDisplayMetricsParam() {
  return window?.location?.pathname?.includes("metrics");
}
