import { lowerCaseAndTrimString } from "./stringFunctions";

/**
 * Note: the type of the return value is the same type as the input object.
 * Removing a key of variable type is very complicated, so for now this is the best we can do.
 * This works well for Records where the keys are dynamic, but not so much for objects with explicit definitions.
 */
export function removeKeyFromObject<T extends Record<string, unknown>, U extends keyof T>(obj: T, key: U) {
  // _.omit can be a performance issue for large objects
  const { [key]: omitted, ...rest } = obj;
  return rest as T;
}

export function getObjectUserEmail<T extends { userEmail: string }>(obj: T) {
  try {
    if (!obj?.userEmail) {
      return null;
    }
    return lowerCaseAndTrimString(obj.userEmail);
  } catch (e) {
    return null;
  }
}

export function getObjectEmail<T extends { email: string }>(obj: T) {
  try {
    return lowerCaseAndTrimString(obj.email);
  } catch (e) {
    return null;
  }
}

export function addDefaultToObject<T extends Record<string, unknown>>(obj: T) {
  return obj || {};
}
