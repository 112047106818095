import { type Styles as ModalStyles } from "react-modal";
import { shadeInputColor } from "../services/commonUsefulFunctions";
import {
  DARK_MODE_BACKGROUND_COLOR,
  DARK_MODE_MODAL_TEXT_COLOR,
  DARK_MODE_SECONDARY_TEXT_COLOR,
  DARK_MODE_TEXT_COLOR,
  DEFAULT_FONT_COLOR,
  LIGHT_MODE_SECONDARY_TEXT_COLOR,
} from "../services/globalVariables";
import { isTemporaryAIEvent } from "./availabilityFunctions";
import {
  doesEventSpanMultipleDays,
  shouldShowTransparentMergedBackground,
} from "./eventFunctions";
import { MODAL_CONTENT_BOX_SHADOW, MODAL_OVERLAY_Z_INDEXES } from "./modalFunctions";
import { isFindTimeEventFormEvent } from "./temporaryEventFunctions";

let cacheForLchSupport = undefined;
let cacheForThinPointFivePxBorderColor = undefined;

export function getDefaultFontColor(isDarkMode: Optional<boolean>) {
  return isDarkMode ? DARK_MODE_TEXT_COLOR : DEFAULT_FONT_COLOR;
}

export function getDefaultSecondaryTextColor(isDarkMode: Optional<boolean>) {
  return isDarkMode
    ? DARK_MODE_SECONDARY_TEXT_COLOR
    : LIGHT_MODE_SECONDARY_TEXT_COLOR;
}

export function getSwitchOffColor(isDarkMode: Optional<boolean>) {
  return isDarkMode ? "#67686F" : "#C8CDD4";
}

export function getTentativeBackgroundStyle(color: string) {
  return `repeating-linear-gradient(
    45deg,
    ${color},
    ${color} 10px,
    ${shadeInputColor(color)} 10px,
    ${shadeInputColor(color)} 20px`;
}

export function getTentativeSideBarBackgroundStyle({
  color,
  isDarkMode,
}: {
  color: string;
  isDarkMode: Optional<boolean>;
}) {
  const defaultBackgroundColor = getDefaultBackgroundColor(isDarkMode);
  return `repeating-linear-gradient(
    45deg,
    ${color},
    ${color} 3px,
    ${defaultBackgroundColor} 3px,
    ${defaultBackgroundColor} 6px`;
}

export function getDefaultBackgroundColor(isDarkMode: Optional<boolean>) {
  return isDarkMode ? DARK_MODE_BACKGROUND_COLOR : "white";
}

export function getDefaultMonthlyCalendarOutOfRangeColor(
  isDarkMode: Optional<boolean>
) {
  // "#202027" is taken from inspector because the original color has transparency
  return isDarkMode ? "#202027" : "#F8F9FA";
}

const MODAL_BACKGROUND_TYPE = {
  WITH_OPACITY: "WITH_OPACITY",
  WITHOUT_OPACITY: "WITHOUT_OPACITY",
} as const;

function getDarkModeModalBackgroundColorWithNoOpacity(
  type: ValueOf<typeof MODAL_BACKGROUND_TYPE>
) {
  switch (type) {
    case MODAL_BACKGROUND_TYPE.WITH_OPACITY:
      return "rgba(52, 54, 69, 0.5)";
    case MODAL_BACKGROUND_TYPE.WITHOUT_OPACITY:
      return "rgba(52, 54, 69, 1)";
    default:
      return "rgba(52, 54, 69, 1)";
  }
}

export function getDefaultModalBorder(isDarkMode: Optional<boolean>) {
  return isDarkMode ? "1px solid #3C3E4D" : "1px solid #E5E7EB"; // .default-bottom-left-modal-border
}

export function getCalendarGridColor(isDarkMode: Optional<boolean>) {
  return isDarkMode ? "#343645" : "#E5E7EB";
}

export function getModalBackgroundColorWithNoOpacity(
  isDarkMode: Optional<boolean>
) {
  return isDarkMode
    ? getDarkModeModalBackgroundColorWithNoOpacity(
        MODAL_BACKGROUND_TYPE.WITHOUT_OPACITY
      )
    : "rgb(255, 255, 255)";
}

export const getModalBackgroundColorWithExtraOpacity = (
  isDarkMode: Optional<boolean>
) => {
  return isDarkMode
    ? getDarkModeModalBackgroundColorWithNoOpacity(
        MODAL_BACKGROUND_TYPE.WITH_OPACITY
      )
    : "rgba(255, 255, 255, 0.4)";
};

/**
 * TODO: This should also accept the type created by formatEventForReactBigCalendar once that is
 * migrated to TypeScript.
 */
export function shouldShowTransparentEventBackgroundInMonthlyView({
  event,
  isInMonthlyView,
}: {
  event: TemporaryEvent;
  isInMonthlyView?: boolean;
}) {
  if (!isInMonthlyView) {
    return false;
  }
  if (shouldShowTransparentMergedBackground(event)) {
    // this is taken care of by `background` property
    return false;
  }
  // we only show background color on the event if it's an all day event or event that spans multiple days
  return (
    !event?.displayAsAllDay &&
    !doesEventSpanMultipleDays(event) &&
    !(isTemporaryAIEvent(event) || isFindTimeEventFormEvent(event))
  );
}

export function getTransparentModalStyle({
  top,
  isDarkMode,
  right,
}): ModalStyles {
  return {
    overlay: {
      position: "fixed",
      inset: 0,
      backgroundColor: "transparent",
      zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
    },
    content: {
      ...{
        padding: "0px",
        position: "absolute",
        top,
        right,
        left: "auto",
        bottom: "auto",
        zIndex: 5,
        backgroundColor: getModalBackgroundColorWithNoOpacity(isDarkMode),
        color: isDarkMode ? DARK_MODE_MODAL_TEXT_COLOR : "",
      },
      ...getSidePoppedOverModalBorder(isDarkMode),
    },
  };
}

export function getDefaultModalStyle(): ModalStyles {
  return {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.70)",
      zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderColor: "transparent",
      borderRadius: 5,
      boxShadow: MODAL_CONTENT_BOX_SHADOW,
      zIndex: 5,
    },
  };
}

export const LIGHT_MODE_AVAILABILITY_BORDER_COLOR = "rgba(215, 231, 252, 1)";
export function getAvailabilityPanelPreviewStyle({ isDarkMode }) {
  return {
    backgroundColor: isDarkMode ? "#343645" : "rgba(243, 247, 254, 1)",
    border: isDarkMode
      ? undefined
      : `1px solid ${LIGHT_MODE_AVAILABILITY_BORDER_COLOR}`,
  };
}

export const GOLD_STAR_HEX_CODE = "#C99E35";

function checkForThinPointFivePxBorderColor() {
  try {
    if (cacheForThinPointFivePxBorderColor === undefined) {
      // @ts-ignore
      cacheForThinPointFivePxBorderColor = CSS.supports(
        "border",
        "0.5px solid lch(86.5 0 282.863)"
      );
    }
    return cacheForThinPointFivePxBorderColor;
  } catch (e) {
    return false;
  }
}

function checkForLchSupport() {
  try {
    if (cacheForLchSupport === undefined) {
      // @ts-ignore
      cacheForLchSupport = CSS.supports(
        "box-shadow",
        "lch(0 0 0 / 0.07) 0px 3px 8px, lch(0 0 0 / 0.07) 0px 2px 5px, lch(0 0 0 / 0.07) 0px 1px 1px"
      );
    }
    return cacheForLchSupport;
  } catch (e) {
    return false;
  }
}

// https://getcssscan.com/css-box-shadow-examples
export const BOX_SHADOW = {
  ZERO: checkForLchSupport()
    ? "lch(0 0 0 / 0.07) 0px 3px 8px, lch(0 0 0 / 0.07) 0px 2px 5px, lch(0 0 0 / 0.07) 0px 1px 1px"
    : "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
  FOUR: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  SIX: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  EIGHT:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
  NINE: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  ELEVEN:
    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
  TWELVE:
    "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
  NINETEEN:
    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  TWENTY_ONE:
    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
  TWENTY_SEVEN:
    "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
  FOURTY_TWO:
    "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
  FIFTY_TWO:
    "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
  FIFTY_SIX:
    "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
  FIFTY_SEVEN:
    "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
  FIFTY_EIGHT: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
  SIXTY_FIVE:
    "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
  SEVENTY_SIX: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
  SEVENTY_SEVEN: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
} as const;

const THIN_POINT_FIVE_PX_BORDER_COLOR = checkForThinPointFivePxBorderColor()
  ? "0.5px solid lch(86.5 0 282.863)"
  : "1px solid lch(86.5 0 282.863)";
export const SOFT_BORDER = {
  DARK_MODE: "1px solid #3C3E4D",
  LIGHT_MODE: "1px solid rgba(112, 115, 147, 0.1)",
} as const;

export function getSidePoppedOverModalBorder(isDarkMode: Optional<boolean>) {
  if (isDarkMode) {
    return {
      border: SOFT_BORDER.DARK_MODE,
      borderRadius: 6,
    };
  }

  return {
    border: THIN_POINT_FIVE_PX_BORDER_COLOR,
    boxShadow: BOX_SHADOW.ZERO,
    borderRadius: 6,
  };
}

export const SCHEDULING_ASSISTANT_MODAL_WIDTH = undefined;

export function getTitleWithBackup(title: string | undefined | null) {
  return title || "No title";
}
