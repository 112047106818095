import {
  handleError,
  getMemoryUsageData,
  isTestEnvironment,
  getBrowserType,
} from "../services/commonUsefulFunctions";
import Fetcher from "../services/fetcher";
import { constructRequestURL } from "../services/api";
import _ from "underscore";
import packageJson from "../../../package.json";
import { differenceInMinutes } from "date-fns";
import { getSignupAttribution } from "../services/queryParamFunctions";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { getUserToken } from "../lib/userFunctions";
import { isUserMaestroUser } from "../services/maestroFunctions";
import { getMasterAccountFromStore } from "../lib/zustandFunctions";
import { lowerCaseAndTrimStringWithGuard } from "../lib/stringFunctions";

let lastMemoryTrack: Date;
const bqErrorTrackerIndex = new Set(); // track errors for sentry so we dont' send the same error repeatedly

interface TrackOnLoginLoginAttributionOptions {
  isOutlook: boolean
  src: string
}

export function trackOnLoginLoginAttribution({ isOutlook, src }: TrackOnLoginLoginAttributionOptions) {
  const attribution = getSignupAttribution();
  if (attribution) {
    trackEvent({
      category: "attribution",
      action: `onClick_login_attribution_${
        isOutlook ? `${src}_outlook` : `${src}_google`
      }`,
      label: attribution,
    });
  }
}

interface TrackReferralOptions {
  user: User | TruncatedUser
  action: string
}

export function trackReferral({ user, action }: TrackReferralOptions) {
  trackEvent({
    category: "web_tracking",
    action,
    label: "referral",
    userToken: getUserToken(user),
  });
}

interface TrackUserInfoOptions {
  action: string
  label: string
  userToken?: string
}

export function trackUserInfo({ action, label, userToken }: TrackUserInfoOptions) {
  if (isTestEnvironment()) {
    return;
  }
  trackEvent({
    category: "userInfo",
    action,
    label,
    userToken,
  });
}

interface TrackFeatureUsageOptions {
  action: string
  userToken?: string
}

export function trackFeatureUsage({ action, userToken }: TrackFeatureUsageOptions) {
  if (isTestEnvironment()) {
    return;
  }
  // Track:
  // V,
  // event form -> create/edit
  // slots
  // personal links
  // meet with
  // time travel
  // group vote
  trackEvent({
    category: "web_tracking",
    action,
    label: "feature_tracking",
    userToken,
  });
}

interface TrackEventOptions {
  category: string
  action: string
  event_name?: string
  slots_type?: string
  label: string
  userToken?: string
}

export function trackEvent({
  category,
  action,
  event_name,
  slots_type,
  label,
  userToken = "",
}: TrackEventOptions) {
  if (isTestEnvironment()) {
    return;
  }

  const NOW = new Date();
  const version = packageJson?.version || "no_version";
  const masterAccount = getMasterAccountFromStore();
  let data = {
    // Rails treats 'action' as the name of the route action, so we can't use it directly
    event_action: action,
    category,
    event_name,
    slots_type,
    label,
    timestamp: NOW.toISOString(),
    client: getBrowserType(),
    url: window.location.href,
    version: `version: ${version} || isVimcalEA: ${isUserMaestroUser(masterAccount)}`,
    user_token: userToken,
  };

  if (!lastMemoryTrack || differenceInMinutes(NOW, lastMemoryTrack) > 1) {
    lastMemoryTrack = NOW;
    data = { ...data, ...getMemoryUsageData() };
  }

  const path = "metrics";
  const url = constructRequestURL(path);

  const payloadData = {
    headers: getDefaultHeaders(),
    body: JSON.stringify(data),
  };

  Fetcher.post(url, payloadData, false)
    .then(_.noop)
    .catch((error) => {
      // do nothing
    });
}

interface TrackErrorOptions {
  category: string
  errorMessage: string
  userToken?: string
}

export function trackError({ category, errorMessage, userToken }: TrackErrorOptions) {
  if (isTestEnvironment()) {
    return;
  }
  try {
    const loweredCaseMessage = lowerCaseAndTrimStringWithGuard(errorMessage);
    if (loweredCaseMessage && bqErrorTrackerIndex.has(loweredCaseMessage)) {
      return;
    }
    bqErrorTrackerIndex.add(loweredCaseMessage);

    const NOW = new Date();
    const data = {
      // Rails treats 'action' as the name of the route action, so we can't use it directly
      category,
      errorMessage,
      user_token: userToken,
      timestamp: NOW.toISOString(),
      version: packageJson?.version || "no_version",
      client: getBrowserType(),
    };
    const path = "metrics/e";
    const url = constructRequestURL(path);
    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(data),
    };
    Fetcher.post(url, payloadData, false)
      .then(_.noop)
      .catch((error) => {
        // do nothing
      });
  } catch (error) {
    // do nothing
  }
}

interface TrackInstallOptions {
  attribution: string | null
  userToken: string
}

export function trackInstall({ attribution, userToken }: TrackInstallOptions) {
  if (isTestEnvironment()) {
    return;
  }

  const NOW = new Date();
  const data = {
    // Rails treats 'action' as the name of the route action, so we can't use it directly
    attribution,
    user_token: userToken,
    timestamp: NOW.toISOString(),
    client: getBrowserType(),
    version: packageJson?.version || "no_version",
  };

  const path = "metrics/a";
  const url = constructRequestURL(path);

  const payloadData = {
    headers: getDefaultHeaders(),
    body: JSON.stringify(data),
  };

  Fetcher.post(url, payloadData, false)
    .then(_.noop)
    .catch((error) => {
      // do nothing
    });
}

export function testTracking(currentUser: User | TruncatedUser) {
  const userToken = getUserToken(currentUser);
  trackError({
    category: "test",
    errorMessage: "error message",
    userToken,
  });
  trackInstall({
    attribution: "test",
    userToken,
  });
  trackEvent({
    category: "App",
    action: "loaded_app",
    label: "app_loaded",
    userToken,
  });
}

export function addDataLayerTracking(event: DataLayerEvent) {
  try {
    if (isTestEnvironment() || !window?.dataLayer?.push) {
      return;
    }
    window.dataLayer.push(event);
  } catch (error) {
    handleError(error);
  }
}

export const FEATURE_TRACKING_ACTIONS = {
  COPY_PERSONAL_LINK: "copyPersonalLink",
  TIME_TRAVEL: "timeTravel",
  MEET_WITH: "meetWith",
  GROUP_VOTE: "groupVote",
  HOLDS_CREATED: "holdsCreated",
  COPY_SLOTS: "copySlots",
} as const;

export const FEATURE_TRACKING_CATEGORIES = {
  TEAM_PLAN: "team-plan",
} as const;

export const USER_INFO_ACTIONS = {
  GUESSED_TIME_ZONE: "guessedTimeZone",
} as const;
