import { convertToTimeZone } from "./commonUsefulFunctions";
import { parseISO, differenceInMinutes, addMinutes } from "date-fns";
import { protectMidnightCarryOver } from "../lib/rbcFunctions";
import {
  GUESTS_CAN_INVITE_OTHERS,
  GUESTS_CAN_MODIFY,
  GUESTS_CAN_SEE_OTHER_GUESTS,
} from "./eventResourceAccessors";
import { safeJSONParse } from "../lib/jsonFunctions";
import { OUT_OF_OFFICE_DURING_EVENT } from "./googleCalendarService";
import { isEmptyObjectOrFalsey } from "./typeGuards";
import { filterOutResourceAttendees } from "./attendeeFunctions";

export function getTemplateTitle(template) {
  return template?.raw_json?.summary;
}

export function getTemplateStart(template) {
  return template?.raw_json?.start;
}

export function getTemplateStartValue(template) {
  return getTemplateStartDate(template) ?? getTemplateStartDateTime(template);
}

export function getTemplateConferenceData(template) {
  return template?.raw_json?.conferenceData;
}

export function getTemplateLocation(template) {
  return template?.raw_json?.location;
}

export function getTemplateDescription(template) {
  return template?.raw_json?.description;
}

export function getTemplateStartDateTime(template) {
  return template?.raw_json?.start?.dateTime;
}

export function getTemplateStartDate(template) {
  return template?.raw_json?.start?.date;
}

export function getTemplateEndDateTime(template) {
  return template?.raw_json?.end?.dateTime;
}

export function getTemplateEndDate(template) {
  return template?.raw_json?.end?.date;
}

export function getTemplateStartTimeZone(template) {
  return template?.raw_json?.start?.timeZone;
}

export function getTemplateEndTimeZone(template) {
  return template?.raw_json?.end?.timeZone;
}

export function getTemplateOutOfOfficeDeclineMode(template) {
  return template?.raw_json?.outOfOfficeAutoDeclineMode;
}

export function getTemplateOutOfOfficeDeclineMessage(template) {
  return template?.raw_json?.outOfOfficeDeclineMessage;
}

export function isOutOfOfficeTemplate(template) {
  return template?.raw_json?.transparency === OUT_OF_OFFICE_DURING_EVENT;
}

export function isTextTemplate(template) {
  return !template?.raw_json;
}

export function isZoomFromIntegrationTemplate(conferenceData) {
  if (isEmptyObjectOrFalsey(conferenceData)) {
    return false;
  }

  const conferencingName = conferenceData.conferenceSolution?.name;
  if (!conferencingName) {
    return false;
  }

  return (
    conferencingName.toLowerCase().includes("zoom") &&
    conferenceData.entryPoints?.length > 0
  );
}

export function createTemplateRBCEventEnd(
  event,
  currentTimeZone,
  jsStart,
  jsEnd
) {
  const eventEndDate = getTemplateEndDate(event);
  if (eventEndDate) {
    return eventEndDate;
  } else {
    const eventStartDateTime = getTemplateStartDateTime(event);
    const eventEndDateTime = getTemplateEndDateTime(event);

    const jsDateStart = jsStart || parseISO(eventStartDateTime);
    const jsDateEnd = jsEnd || parseISO(eventEndDateTime);

    if (differenceInMinutes(jsDateEnd, jsDateStart) < 15) {
      // set minimum of 15 minutes
      const minimumEndTime = addMinutes(jsDateStart, 15);
      return protectMidnightCarryOver(
        convertToTimeZone(minimumEndTime, { timeZone: currentTimeZone })
      );
    } else {
      return protectMidnightCarryOver(
        convertToTimeZone(jsDateEnd, { timeZone: currentTimeZone })
      );
    }
  }
}

export function isValidEventTemplate(template) {
  return !!getTemplateStart(template);
}

export function isEventTemplateAllDay(template) {
  return !!getTemplateStartDate(template);
}

// TODO: Deprecate this in favor of getTemplateProviderId.
export function getTemplateEmail(template) {
  return template?.raw_json?.google_id;
}

export function getTemplateProviderId(template) {
  return template?.raw_json?.google_id;
}

export function getTemplateTransparency(template) {
  return template?.raw_json?.transparency;
}

export function getTemplateVisibility(template) {
  return template?.raw_json?.visibility;
}

export function getTemplateReminders(template) {
  return template?.raw_json?.reminders;
}

export function getTemplateColorID(template) {
  return template?.raw_json?.colorId;
}

export function getTemplateIsAllDay(template) {
  return template?.raw_json?.allDay;
}
export function getTemplateAttendees(template) {
  return template?.raw_json?.attendees;
}

export function getTemplateHumanAttendees(template) {
  if (isEmptyObjectOrFalsey(template)) {
    return [];
  }
  const attendees = getTemplateAttendees(template);
  const humanAttendees = filterOutResourceAttendees(attendees);
  return humanAttendees;
}

const TEMPLATE_PERMISSIONS_KEY_INDEX = {
  [GUESTS_CAN_MODIFY]: "guestsCanModify",
  [GUESTS_CAN_INVITE_OTHERS]: "guestsCanInviteOthers",
  [GUESTS_CAN_SEE_OTHER_GUESTS]: "guestsCanSeeOtherGuests",
};

export function getTemplateGuestPermissions(template, permission) {
  // values: anyoneCanAddSelf, guestsCanInviteOthers, guestsCanModify, guestsCanSeeOtherGuests
  return template
    ? template[permission] ||
        template[TEMPLATE_PERMISSIONS_KEY_INDEX[permission]]
    : undefined;
}

export function getTemplateDuration(template) {
  return template?.raw_json?.duration;
}

export function getTemplateExtendedProperties(template) {
  return template?.raw_json?.extended_properties;
}

export function getTagsFromTemplate(template) {
  const jsonTags = getTemplateExtendedProperties(template)?.private?.tags;
  if (!jsonTags) {
    return [];
  }

  try {
    return JSON.parse(jsonTags);
  } catch (e) {
    // invalid json here (google could have silently cut the tags string early
    // Find the last complete object in the string.
    const lastCompleteObjectIndex = jsonTags.lastIndexOf("}");

    // Slice the string up to that point, and add the closing array bracket.
    const validJson = jsonTags.slice(0, lastCompleteObjectIndex + 1) + "]";

    // Now it should be valid JSON, so you can parse it.
    return safeJSONParse(validJson) ?? [];
  }
}

export function getTemplateId(template) {
  return template?.id;
}
