import {
  determineConferencingFromCurrentUser,
  createDefaultPersonalLinkEventSummary,
  createAbbreviationForTimeZone,
  guessTimeZone,
  generateAvailabilityToken,
  updateToStartOfNextDayIfLastSlot,
  determineRBCEventEndWithEventStart,
  isBeforeMinute,
  isAfterMinute,
  sortEventsJSDate,
  getTimeInAnchorTimeZone,
  removeDuplicatesFromArray,
  createLabelAndValueForReactSelect,
  localData,
  isSameOrBeforeMinute,
  isSameOrAfterMinute,
  RoundToClosestMinuteJSDate,
  shouldRoundToNearest15,
  isAfterDay,
  handleError,
  removeSpecialHTMLCharacters,
  isHTMLText,
  getRandomElements,
  getCurrentTimeInCurrentTimeZone,
  convertToTimeZone,
  generateBookableSlotsFromObj,
  RoundDownToClosestMinute,
  isValidJSDate,
} from "../services/commonUsefulFunctions";
import GoogleCalendarService, {
  ATTENDEE_EVENT_ATTENDING,
  BACKEND_HANGOUT,
  BACKEND_PHONE,
  BACKEND_ZOOM,
  ZOOM_STRING,
  createWhatsAppConferenceData,
  createPhoneConferenceData,
  BACKEND_WHATS_APP,
  BACKEND_CUSTOM_CONFERENCING,
  createCustomConferencing,
  ATTENDEE_EVENT_NEEDS_ACTION,
  DATE_TIME_24_HOUR_FORMAT,
} from "../services/googleCalendarService";
import { conferenceOptionFromBackend } from "../services/googleCalendarHelpers";
import {
  SELECT_AVAILABILITY_COLOR,
  SLOTS_SELECT_TYPE_PLAIN_TEXT,
  SLOTS_SELECT_TYPE_TEXT_URL,
  SLOTS_SELECT_TYPE_HYPER_LINKED,
  ISO_DATE_FORMAT,
  SLOTS_LINK_ALONE,
  ALL_BOOKING_VARIABLES,
  BACKEND_MONTH,
  ROLLING_SEVEN_DAY,
} from "../services/globalVariables";
import {
  startOfMinute,
  isSameMinute,
  parseISO,
  format,
  formatISO,
  addMinutes,
  getMinutes,
  getHours,
  isSameWeek,
  differenceInMinutes,
  addDays,
  endOfDay,
  differenceInDays,
  subDays,
  startOfWeek,
  startOfMonth,
  isAfter,
  getYear,
  set,
} from "date-fns";
import {
  splitAvailableTimesIntoDuration,
  convertISOSlotsArrayToJSDate,
  determineGroupVoteURL,
} from "../components/scheduling/schedulingSharedVariables";
import { DEFAULT_CUSTOM_QUESTIONS } from "../components/customQuestions.tsx";
import { filterEventsInThePast, isBusyEvent } from "./eventFunctions";
import {
  isEventSlotAllDayEvent,
  protectMidnightCarryOver,
} from "./rbcFunctions";
import {
  SLOTS_PLAIN_TEXT,
  SLOTS_PLAIN_TEXT_URL,
  SLOTS_RICH_TEXT,
  SLOTS_STYLE,
  SLOTS_PLAIN_TEXT_COPY,
  SLOTS_PLAIN_TEXT_URL_COPY,
  SLOTS_PLAIN_TEXT_URL_LINK_COPY,
  SLOTS_RICH_TEXT_COPY,
  HOLD_TITLE,
  HOLD_COLOR_ID,
  LINK_ALONE,
  ADD_ATTENDEE_TO_HOLDS,
  ENVIRONMENTS,
} from "./vimcalVariables";
import {
  getCalendarFromEmail,
  getUserPrimaryCalendar,
} from "./calendarFunctions";
import { addEventConferenceData } from "../services/eventResourceAccessors";
import {
  getCalendarEmail,
  getCalendarProviderId,
  getCalendarUserCalendarID,
} from "../services/calendarAccessors";
import { isVersionV2 } from "../services/versionFunctions";
import { OUTLOOK_CONFERENCING } from "../../js/resources/outlookVariables";
import { isOutlookUser } from "./outlookFunctions";
import {
  getMasterAccountCreatedAtJSDate,
  getSlotsSettingsShowAllTimeZones,
  getUserEmail,
  getUserName,
} from "./userFunctions";
import { getUtcOffset } from "./timeFunctions";
import { isUserMaestroUser } from "../services/maestroFunctions";
import { constructRequestURL } from "../services/api";
import Fetcher from "../services/fetcher";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import { LOCAL_DATA_ACTION, getCurrentUserEmail } from "./localData";
import {
  TEMPORARY_EVENT_TYPES,
  createEventFormFindTimeTemporaryEvent,
} from "./temporaryEventFunctions";
import { immutablySortArray, isEmptyArray } from "./arrayFunctions";
import { formatISOAllDayDate, getDateTimeFormat, isValidWeekStart } from "./dateFunctions";
import { parseHoldEventAttendees } from "../services/holdFunctions";
import { isRichTextAndEmpty, isUrl, lowerCaseAndTrimString } from "./stringFunctions";
import { allowDefaultToEmptySlotsTitle, shouldShowSlotsTextFormat } from "./featureFlagFunctions";
import { getDefaultHeaders } from "./fetchFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isNullOrUndefined, isTypeNumber, isTypeObject } from "../services/typeGuards";
import { getWorkHours } from "./settingsFunctions";
import { createUUID } from "../services/randomFunctions";
import { getGroupVoteLinkEventsHold, getUserConnectedAccountDetails, isUserFromMagicLink } from "../services/maestro/maestroAccessors";
import { getGroupVoteToken } from "./groupVoteFunctions";
import { getEventsHoldToken } from "../services/maestro/maestroAccessors";
import { BACKEND_IGNORE_INTERNAL_CONFLICTS_KEY } from "./personalLinkFunctions";
import { getObjectEmail } from "./objectFunctions";

const { availability } = GoogleCalendarService;
const ALL_DAY_EVENT_STRING_FORMAT = "yyyy-M-d"; // need to use this instead of the import from scheduling shared variables because of legacy booking project otherwise keys won't match up

export const ALL_DEFAULT_CUSTOM_QUESTIONS = {
  EMAIL: "Email",
  NAME: "Name",
  COMPANY: "Company",
};

// this is used to fetch availability for a user
const DEFAULT_DAY_OF_WEEK_START_END = {
  START: 9,
  END: 17,
};

export function getDefaultWeekWithWorkHours({ workHours }) {
  const { startWorkHour, endWorkHour } = workHours;

  return {
    Monday: [
      {
        end: {
          hour: endWorkHour,
          minute: "0",
        },
        start: {
          hour: startWorkHour,
          minute: "0",
        },
      },
    ],
    Tuesday: [
      {
        end: {
          hour: endWorkHour,
          minute: "0",
        },
        start: {
          hour: startWorkHour,
          minute: "0",
        },
      },
    ],
    Wednesday: [
      {
        end: {
          hour: endWorkHour,
          minute: "0",
        },
        start: {
          hour: startWorkHour,
          minute: "0",
        },
      },
    ],
    Thursday: [
      {
        end: {
          hour: endWorkHour,
          minute: "0",
        },
        start: {
          hour: startWorkHour,
          minute: "0",
        },
      },
    ],
    Friday: [
      {
        end: {
          hour: endWorkHour,
          minute: "0",
        },
        start: {
          hour: startWorkHour,
          minute: "0",
        },
      },
    ],
  };
}

export const DEFAULT_WEEK_DAY_SLOTS = {
  Monday: [
    {
      end: {
        hour: DEFAULT_DAY_OF_WEEK_START_END.END,
        minute: "0",
      },
      start: {
        hour: DEFAULT_DAY_OF_WEEK_START_END.START,
        minute: "0",
      },
    },
  ],
  Tuesday: [
    {
      end: {
        hour: DEFAULT_DAY_OF_WEEK_START_END.END,
        minute: "0",
      },
      start: {
        hour: DEFAULT_DAY_OF_WEEK_START_END.START,
        minute: "0",
      },
    },
  ],
  Wednesday: [
    {
      end: {
        hour: DEFAULT_DAY_OF_WEEK_START_END.END,
        minute: "0",
      },
      start: {
        hour: DEFAULT_DAY_OF_WEEK_START_END.START,
        minute: "0",
      },
    },
  ],
  Thursday: [
    {
      end: {
        hour: DEFAULT_DAY_OF_WEEK_START_END.END,
        minute: "0",
      },
      start: {
        hour: DEFAULT_DAY_OF_WEEK_START_END.START,
        minute: "0",
      },
    },
  ],
  Friday: [
    {
      end: {
        hour: DEFAULT_DAY_OF_WEEK_START_END.END,
        minute: "0",
      },
      start: {
        hour: DEFAULT_DAY_OF_WEEK_START_END.START,
        minute: "0",
      },
    },
  ],
};

export const DEFAULT_PRE_SLOTS_COPY =
  "Do any of these times ({{time_zone}}) work for you?";
export const DEFAULT_LINK_COPY =
  "If it's easier, you can click on a slot below to book:";

export function getSlotsPresets({ allCalendars, currentUser, masterAccount }) {
  if (isEmptyObjectOrFalsey(currentUser?.availability_settings)) {
    return getSlotsDefaults({ allCalendars, currentUser, masterAccount });
  }

  const { availability_settings: availabilitySettings } = currentUser;

  const {
    plainTextCopy: defaultPlainTextCopy,
    plainTextURLCopy: defaultPlainTextURLCopy,
    plainTextURLLinkCopy: defaultPlainTextURLLinkCopy,
    richTextCopy: defaultRichTextCopy,
    title: defaultTitle,
    duration: defaultDuration,
    googleId: defaultGoogleId,
    location: defaultLocation,
    description: defaultDescription,
    customQuestions: defaultCustomQuestions,
    combineAdjacentSlots: defaultCombineAdjacentSlots,
    [HOLD_TITLE]: defaultHoldTitle,
    holdColorID: defaultHoldColorID,
    [ADD_ATTENDEE_TO_HOLDS]: defaultShouldAddAttendeesToHolds,
    bufferBeforeEvent: defaultBufferEventBefore,
    bufferAfterEvent: defaultBufferEventAfter,
  } = getSlotsDefaults({ allCalendars, currentUser, masterAccount });

  const {
    title,
    duration,
    calendar_provider_id,
    google_calendar_id,
    location,
    description,
    custom_questions,
    buffer_from_now,
    allow_reschedule_and_cancel,
    combine_adjacent_slots,
    buffer_before,
    buffer_after,
  } = availabilitySettings;

  const conferencing = determineConferencingFromCurrentUser(
    currentUser,
    allCalendars,
  );

  const getProviderID = () => {
    if (isVersionV2()) {
      return calendar_provider_id;
    } else {
      return google_calendar_id;
    }
  };

  const getDescription = () => {
    return (isRichTextAndEmpty(description) ? "" : description) || defaultDescription;
  };

  const getTitle = () => {
    if (allowDefaultToEmptySlotsTitle(currentUser)) {
      return title ?? defaultTitle;
    }
    return title || defaultTitle;
  };

  return {
    plainTextCopy:
      availabilitySettings[SLOTS_PLAIN_TEXT_COPY] || defaultPlainTextCopy,
    plainTextURLCopy:
      availabilitySettings[SLOTS_PLAIN_TEXT_URL_COPY] ||
      defaultPlainTextURLCopy,
    plainTextURLLinkCopy:
      availabilitySettings[SLOTS_PLAIN_TEXT_URL_LINK_COPY] ||
      defaultPlainTextURLLinkCopy,
    richTextCopy:
      availabilitySettings[SLOTS_RICH_TEXT_COPY] || defaultRichTextCopy,
    title: getTitle(),
    duration: duration || defaultDuration,
    conferencing: conferencing,
    googleId: getProviderID() || defaultGoogleId,
    location: location || defaultLocation,
    description: getDescription(),
    customQuestions: custom_questions || defaultCustomQuestions,
    bufferFromNow: buffer_from_now ?? 0,
    allowRescheduleAndCancel: allow_reschedule_and_cancel ?? true,
    combineAdjacentSlots: combine_adjacent_slots ?? defaultCombineAdjacentSlots,
    [HOLD_TITLE]: availabilitySettings[HOLD_TITLE] ?? defaultHoldTitle,
    [HOLD_COLOR_ID]: availabilitySettings[HOLD_COLOR_ID] || defaultHoldColorID,
    [ADD_ATTENDEE_TO_HOLDS]:
      availabilitySettings[ADD_ATTENDEE_TO_HOLDS] ??
      defaultShouldAddAttendeesToHolds,
    contentShowsAllTimeZones: getSlotsSettingsShowAllTimeZones({
      currentUser,
      masterAccount,
    }),
    bufferBeforeEvent: buffer_before ?? defaultBufferEventBefore,
    bufferAfterEvent: buffer_after ?? defaultBufferEventAfter,
  };
}

function getSlotsDefaults({ allCalendars, currentUser, masterAccount }) {
  const defaultSlotCopies = getDefaultSlotCopies();
  // We get the provider_id key from the primary calendar
  // For Google, this is the user's email address
  const calendarProviderId = isOutlookUser(currentUser)
    ? getCalendarProviderId(
        getUserPrimaryCalendar({ allCalendars, email: currentUser.email })
      )
    : currentUser.email;

  return {
    plainTextCopy: defaultSlotCopies[SLOTS_PLAIN_TEXT_COPY],
    plainTextURLCopy: defaultSlotCopies[SLOTS_PLAIN_TEXT_URL_COPY],
    plainTextURLLinkCopy: defaultSlotCopies[SLOTS_PLAIN_TEXT_URL_LINK_COPY],
    richTextCopy: defaultSlotCopies[SLOTS_RICH_TEXT_COPY],
    title: createDefaultPersonalLinkEventSummary({
      user: currentUser,
      masterAccount,
    }),
    duration: 30,
    conferencing: determineConferencingFromCurrentUser(
      currentUser,
      allCalendars
    ),
    googleId: calendarProviderId,
    location: "",
    description: "",
    customQuestions: DEFAULT_CUSTOM_QUESTIONS,
    combineAdjacentSlots: true,
    [HOLD_TITLE]: "Hold",
    [HOLD_COLOR_ID]: null, // default to null which uses the current user default calendar color
    contentShowsAllTimeZones: isUserMaestroUser(masterAccount),
    [ADD_ATTENDEE_TO_HOLDS]: false,
    bufferBeforeEvent: 0,
    bufferAfterEvent: 0,
  };
}

export function convertCopyFromPlaceHolders({
  copy,
  currentTimeZone,
  duration,
  extraTimeZones,
  showAllTimeZones,
  date,
}) {
  const timeZoneReg = new RegExp("{{time_zone}}", "gmi");
  const durationReg = new RegExp("{{duration}}", "gmi");
  const allTimeZones =
    extraTimeZones?.length > 0
      ? removeDuplicatesFromArray([currentTimeZone, ...extraTimeZones])
      : [currentTimeZone];
  const shouldAddMultipleTimeZones =
    showAllTimeZones && extraTimeZones?.length > 0;
  const abbreviatedTimeZoneText = shouldAddMultipleTimeZones
    ? getListOfTimeZoneAbbreviationsForSlotsHeader({
        timeZones: allTimeZones,
        date,
      })
    : createAbbreviationForTimeZone(currentTimeZone ?? guessTimeZone(), date);

  return copy
    .replace(timeZoneReg, abbreviatedTimeZoneText)
    .replace(durationReg, duration || 30);
}

export function getGroupVoteLinkInfo({
  currentUser,
  bookingLink,
  canEdit,
  isDuplicate,
  allCalendars,
  defaultUserTimeZone,
  isNew,
}) {
  if (isEmptyObjectOrFalsey(bookingLink)) {
    return createDefaultGroupVoteObject({
      currentUser,
      allCalendars,
      defaultUserTimeZone,
    });
  }

  const {
    title,
    duration,
    description,
    conferencing,
    calendar_provider_id,
    location,
    token,
    time_zone,
    anonymous,
    selected_slots, // comes in as array of {start: isoString, end: isoString}
    slug,
  } = bookingLink;
  const timeZone = time_zone ?? defaultUserTimeZone ?? guessTimeZone();

  const determineSlots = () => {
    if (isEmptyArray(selected_slots)) {
      return [];
    }

    return getSelectedSlotsFromGroupVoteLink({ bookingLink, canEdit, isDuplicate, isNew });
  };

  return {
    title,
    duration,
    description,
    conferencing: conferenceOptionFromBackend(conferencing, currentUser),
    calendarProviderID: calendar_provider_id,
    location,
    token: token || generateAvailabilityToken(currentUser),
    attendees: getAttendees(bookingLink),
    timeZone,
    hidePoll: anonymous,
    selectedSlots: determineSlots(),
    slug,
    eventsHold: getGroupVoteLinkEventsHold({ bookingLink }),
    criticalAttendees: getCriticalAttendees(bookingLink),
  };
}

function createDefaultGroupVoteObject({
  currentUser,
  allCalendars,
  defaultUserTimeZone,
}) {
  return {
    title: "",
    duration: null,
    description: "",
    conferencing: determineConferencingFromCurrentUser(
      currentUser,
      allCalendars,
    ),
    calendarProviderID: getCalendarProviderId(getUserPrimaryCalendar({
      allCalendars,
      email: currentUser?.email,
    })),
    location: "",
    token: generateAvailabilityToken(currentUser),
    attendees: [],
    timeZone: defaultUserTimeZone ?? guessTimeZone(),
    hidePoll: false,
    selectedSlots: [],
    criticalAttendees: [],
  };
}

export function isTemporaryAIEvent(event) {
  return event?.isTemporaryAIEvent;
}

export function isGroupVoteEvent(event) {
  return event?.isGroupVote;
}

export function isOutstandingSlotEvent(event) {
  return event?.status === TEMPORARY_EVENT_TYPES.OUTSTANDING_SLOT_EVENT;
}

export function createTemporaryEvent({
  startTime,
  endTime,
  index,
  hideCancel,
  isTemporaryAIEvent,
  isGroupVote = false,
  resourceId = null,
  isNew = true, // We disable drag if false, so default to true
}) {
  const timeEnd = updateToStartOfNextDayIfLastSlot(endTime);
  const isAllDayEvent = isEventSlotAllDayEvent({
    eventStart: startTime,
    eventEnd: endTime,
  });
  return {
    isTemporary: true,
    isAvailability: true,
    isTemporaryAIEvent: isTemporaryAIEvent ?? false,
    eventStart: startTime,
    index,
    eventEnd: timeEnd,
    event_end: isAllDayEvent
      ? { date: formatISO(endTime, ISO_DATE_FORMAT) }
      : { dateTime: endTime.toISOString() },
    rbcEventEnd: protectMidnightCarryOver(
      determineRBCEventEndWithEventStart(startTime, timeEnd)
    ),
    backgroundColor: SELECT_AVAILABILITY_COLOR,
    status: availability,
    raw_json: { status: availability },
    id: createUUID(),
    hideCancel,
    isGroupVote: isGroupVote,
    displayAsAllDay: isAllDayEvent,
    resourceId: resourceId || getCurrentUserEmail() || "",
    start_time_utc: startOfMinute(startTime).toISOString(),
    end_time_utc: startOfMinute(timeEnd).toISOString(),
    isNew,
  };
}

export function determineBookingURL() {
  const environment = process.env.REACT_APP_CLIENT_ENV;
  const bookingUrl = process.env.REACT_APP_BOOKING_URL;

  if (bookingUrl) {
    return bookingUrl;
  }

  switch (environment) {
    case ENVIRONMENTS.DEV:
      return "https://book-dev.vimcal.com";
    case ENVIRONMENTS.STAGING:
      return "https://book-staging.vimcal.com";
    case ENVIRONMENTS.DOGFOOD:
      return "https://book-dogfood.vimcal.com";
    case ENVIRONMENTS.PRODUCTION:
      return "https://book.vimcal.com";
    case ENVIRONMENTS.TESTING:
      return "https://book-testing.vimcal.com";
    default:
      return "https://book-dev.vimcal.com";
  }
}

export function getSelectedSlotsFromGroupVoteLink({
  bookingLink,
  canEdit,
  isDuplicate,
  isNew,
}) {
  if (isEmptyObjectOrFalsey(bookingLink)) {
    return [];
  }

  const { selected_slots, time_zone } = bookingLink;

  const convertedSlots = isDuplicate
    ? getNonExpiredSelectedSlots(bookingLink)
    : convertISOSlotsArrayToJSDate(selected_slots, time_zone);

  return convertedSlots.map((s, index) => {
    const { eventStart, eventEnd } = s;

    return createTemporaryEvent({
      startTime: eventStart,
      endTime: eventEnd,
      index,
      hideCancel: !canEdit,
      isGroupVote: true,
      isNew,
    });
  });
}

export function getChoppedSelectedSlotsFromGroupVoteLink(
  bookingLink,
  slotCountIndex = {}
) {
  if (isEmptyObjectOrFalsey(bookingLink)) {
    return [];
  }
  const { selected_slots, time_zone, duration } = bookingLink;

  const choppedUpSlots = splitAvailableTimesIntoDuration(
    convertISOSlotsArrayToJSDate(selected_slots, time_zone),
    duration
  );
  return choppedUpSlots.map((s, index) => {
    const { eventStart, eventEnd } = s;
    const key = createKeyFromSlot(s);
    const slotCount =
      slotCountIndex && slotCountIndex[key] ? slotCountIndex[key].length : 0;

    return {
      ...createTemporaryEvent({
        startTime: eventStart,
        endTime: eventEnd,
        index,
        hideCancel: true,
      }),
      displayCheckBox: true,
      slotCount,
      isGroupVote: true,
    };
  });
}

export function getAttendees(bookingLink) {
  if (!bookingLink?.attendees) {
    return [];
  }

  const { attendees } = bookingLink;
  const attendeesWithSelectedOptions = attendees.filter(
    (a) => a?.slots.length > 0
  );
  const selectedEmails = attendeesWithSelectedOptions.map((a) =>
    lowerCaseAndTrimString(a?.email)
  );
  const attendeesWithOutSelectedOptions = attendees
    .filter((a) => a?.slots.length === 0 || !a?.slots)
    .filter((a) => !selectedEmails.includes(lowerCaseAndTrimString(a?.email)));
  return attendeesWithSelectedOptions.concat(attendeesWithOutSelectedOptions);
}

export function determineSlotCriticalAttendeeIndex(bookingLink) {
  // get which attendee clicked on which slot
  const attendees = getAttendees(bookingLink);
  const criticalAttendees = getCriticalAttendees(bookingLink);
  let slotCriticalAttendeeIndex = {};

  attendees.filter((a) => criticalAttendees.includes(getObjectEmail(a))).forEach((a) => {
    if (isEmptyArray(a.slots)) {
      return;
    }

    a.slots.forEach((s) => {
      const key = createKeyFromSlotISOString(s);
      if (!slotCriticalAttendeeIndex[key]) {
        slotCriticalAttendeeIndex[key] = [getAttendeeNameAndEmailKey(a)];
      } else {
        slotCriticalAttendeeIndex[key] = slotCriticalAttendeeIndex[key].concat(getAttendeeNameAndEmailKey(a));
      }
    });
  });

  return slotCriticalAttendeeIndex;
}

export function determineSlotAttendeeIndex(bookingLink) {
  // get which attendee clicked on which slot
  const attendees = getAttendees(bookingLink);
  let slotAttendeeIndex = {};

  attendees.forEach((a) => {
    if (!a.slots || a.slots.length === 0) {
      return;
    }

    a.slots.forEach((s) => {
      const key = createKeyFromSlotISOString(s);
      if (!slotAttendeeIndex[key]) {
        slotAttendeeIndex[key] = [getAttendeeNameAndEmailKey(a)];
      } else {
        slotAttendeeIndex[key] = slotAttendeeIndex[key].concat(
          getAttendeeNameAndEmailKey(a)
        );
      }
    });
  });

  return slotAttendeeIndex;
}

export function getAttendeeNameAndEmailKey(attendee) {
  return `${attendee?.name ?? ""}_${attendee?.email ?? ""}`;
}

export function getSpreadsheetAttendees(groupVote) {
  if (!groupVote?.attendees) {
    return [];
  }

  const {
    attendees,
  } = groupVote;
  return attendees ?? [];
}

export function createKeyFromSlot(slot, selectedTimeZone) {
  const { eventStart, eventEnd } = slot;
  if (isSameMinute(eventStart, eventEnd) 
    || isMultiDaySlot(slot) 
    || isEventSlotAllDayEvent(slot)
  ) {
    return `${formatISOAllDayDate(eventStart)}_${formatISOAllDayDate(eventEnd)}`;
  }

  const startOfMinuteEventStart = startOfMinute(eventStart);
  const startOfMinuteEventEnd = startOfMinute(eventEnd);

  if (selectedTimeZone && selectedTimeZone !== guessTimeZone()) {
    return `${getTimeInAnchorTimeZone(startOfMinuteEventStart, selectedTimeZone).toISOString()}_${getTimeInAnchorTimeZone(startOfMinuteEventEnd, selectedTimeZone).toISOString()}`;
  }
  return `${startOfMinuteEventStart.toISOString()}_${startOfMinuteEventEnd.toISOString()}`;
}

export function createKeyFromSlotISOString(slot) {
  const { start, end, startDate, endDate } = slot;
  if (startDate) {
    return `${fixAllDayStringFormat(startDate)}_${fixAllDayStringFormat(endDate)}`;
  }
  return `${start}_${end}`;
}

export function isSameSlot(slotA, slotB) {
  if (slotA?.status && slotB?.status && slotA.status !== slotB.status) {
    return false;
  }
  return (
    slotA &&
    slotB &&
    isSameMinute(slotA.eventStart, slotB.eventStart) &&
    isSameMinute(slotA.eventEnd, slotB.eventEnd)
  );
}

export function getInitialBookingLinkDay(bookingLink) {
  if (isEmptyArray(bookingLink?.selected_slots)) {
    return new Date();
  }

  const { selected_slots, time_zone } = bookingLink;
  const jsDateArray = convertISOSlotsArrayToJSDate(selected_slots, time_zone);
  const filteredArray = filterEventsInThePast(jsDateArray);
  let initialStartDate;
  filteredArray.forEach((s) => {
    if (!initialStartDate) {
      initialStartDate = s.eventStart;
    } else if (isBeforeMinute(s.eventStart, initialStartDate)) {
      initialStartDate = s.eventStart;
    }
  });

  return initialStartDate ?? new Date();
}

export function isGroupVoteLinkExpired(bookingLink) {
  if (isEmptyArray(bookingLink?.selected_slots)) {
    return true;
  }

  return getNonExpiredSelectedSlots(bookingLink).length === 0;
}

export function getSlotWithMostNumberOfVotes(attendeesIndex, selectedSlots) {
  let mostPopularKey;
  const NOW = new Date();

  if (selectedSlots.length === 0) {
    // if no selected slots
    return { eventStart: null, eventEnd: null };
  } else if (isEmptyObjectOrFalsey(attendeesIndex)) {
    // no attendee has picked any slots -> choose first one that's
    return selectedSlots.find(
      (s) =>
        s.isAvailability &&
        isAfterMinute(s.eventStart, NOW) &&
        isAfterMinute(s.eventEnd, NOW)
    );
  }

  Object.keys(attendeesIndex).forEach((k) => {
    if (!mostPopularKey) {
      mostPopularKey = k;
    } else if (
      attendeesIndex[k]?.length > attendeesIndex[mostPopularKey]?.length
    ) {
      mostPopularKey = k;
    }
  });

  if (!mostPopularKey) {
    return { eventStart: null, eventEnd: null };
  }

  return getEventStartAndEndFromKey(mostPopularKey);
}

// create fake event that we use for event form
export function createEventFromGroupVoteLink({
  groupVoteLink,
  slot,
  currentUser,
  allCalendars,
  masterAccount,
}) {
  const {
    title,
    description,
    conferencing,
    location,
    // time_zone, // ignore time
  } = groupVoteLink;
  const attendees = getAttendees(groupVoteLink);

  const { eventStart, eventEnd } = slot;

  const determineConferencing = () => {
    switch (conferencing) {
      case BACKEND_HANGOUT:
        return {
          createRequest: {
            requestId: createUUID(),
          },
        };
      case BACKEND_ZOOM:
        return {
          isTemplate: true,
          conferenceType: ZOOM_STRING,
        };
      case BACKEND_PHONE:
        return createPhoneConferenceData({ currentUser, masterAccount });
      case BACKEND_WHATS_APP:
        return createWhatsAppConferenceData({ currentUser, masterAccount });
      case BACKEND_CUSTOM_CONFERENCING:
        return createCustomConferencing(currentUser);
      case OUTLOOK_CONFERENCING.skypeForBusiness:
        return conferencing;
      case OUTLOOK_CONFERENCING.teamsForBusiness:
        return conferencing;
      case OUTLOOK_CONFERENCING.skypeForConsumer:
        return conferencing;
      default:
        return null;
    }
  };

  const calendarId = getCalendarUserCalendarID(
    getCalendarFromEmail(currentUser.email, allCalendars)
  );

  const isAllDayEvent = isEventSlotAllDayEvent(slot);

  let event = {
    eventStart,
    eventEnd: isAllDayEvent ? eventStart : eventEnd,
    defaultStartTime: eventStart.toISOString(),
    defaultEndTime: eventEnd.toISOString(),
    rbcEventEnd: protectMidnightCarryOver(eventEnd),
    user_calendar_id: calendarId,
    calendarId,
    raw_json: {
      description,
      start: {
        dateTime: isAllDayEvent ? null : eventStart.toISOString(),
        date: isAllDayEvent ? formatISO(eventStart, ISO_DATE_FORMAT) : null,
      },
      end: {
        date: isAllDayEvent ? formatISO(eventEnd, ISO_DATE_FORMAT) : null,
        dateTime: isAllDayEvent ? null : eventEnd.toISOString(),
      },
      location,
      summary: title,
      attendees: attendees
        .map((a) => {
          return {
            email: a.email,
            displayName: a.name,
            responseStatus: ATTENDEE_EVENT_NEEDS_ACTION,
          };
        })
        .concat({
          email: currentUser.email,
          displayName: getUserName({ user: currentUser, masterAccount })
            .fullName,
          responseStatus: ATTENDEE_EVENT_ATTENDING,
        }),
    },
    keepAttendees: true,
    allDay: isAllDayEvent,
    displayAsAllDay: formatISO(eventStart, ISO_DATE_FORMAT),
    hold_details: { // need hold_details to auto delete holds
      vholds_id: getEventsHoldToken({ // otherwise we can't tell that we're creating a hold event
        eventsHold: getGroupVoteLinkEventsHold({
          bookingLink: groupVoteLink,
        }),
      }),
      isCreatingNonHoldEvent: true, // Add to prevent entering edit hold event logic in index.js (event form)
    },
  };
  const conferenceData = determineConferencing();
  if (conferenceData) {
    event = addEventConferenceData(event, conferenceData);
  }

  return event;
}

// create fake event that we use for event form
// Same as group vote link but we need the vholds_id to delete existing holds
export function createEventFromSlotsHold({
  holdDetails,
  slot,
  currentUser,
  allCalendars,
  masterAccount,
  userCalendarID,
}) {
  const {
    attendees,
    title,
    description,
    conferencing,
    location,
  } = holdDetails;

  const {
    eventStart,
    eventEnd,
    end_time_utc,
    start_time_utc,
    event_start,
    event_end,
  } = slot;

  const determineConferencing = () => {
    switch (conferencing) {
      case BACKEND_HANGOUT:
        return {
          createRequest: {
            requestId: createUUID(),
          },
        };
      case BACKEND_ZOOM:
        return {
          isTemplate: true,
          conferenceType: ZOOM_STRING,
        };
      case BACKEND_PHONE:
        return createPhoneConferenceData({ currentUser, masterAccount });
      case BACKEND_WHATS_APP:
        return createWhatsAppConferenceData({ currentUser, masterAccount });
      case BACKEND_CUSTOM_CONFERENCING:
        return createCustomConferencing(currentUser);
      case OUTLOOK_CONFERENCING.skypeForBusiness:
        return conferencing;
      case OUTLOOK_CONFERENCING.teamsForBusiness:
        return conferencing;
      case OUTLOOK_CONFERENCING.skypeForConsumer:
        return conferencing;
      default:
        return null;
    }
  };

  const calendarId = userCalendarID ??
                      getCalendarUserCalendarID(
                        getCalendarFromEmail(currentUser.email, allCalendars),
                      ) ??
                      getCalendarUserCalendarID(
                        getUserPrimaryCalendar({ allCalendars, email: currentUser.email }),
                      );

  const isAllDayEvent = isEventSlotAllDayEvent(slot);
  const eventAttendees = parseHoldEventAttendees({
    attendees,
    currentUser,
    masterAccount,
  });

  const backupRawJsonStart = {
    dateTime: isAllDayEvent ? null : eventStart.toISOString(),
    date: isAllDayEvent ? formatISO(eventStart, ISO_DATE_FORMAT) : null,
  };
  const start = event_start || backupRawJsonStart;

  const backupRawJsonEnd = {
    date: isAllDayEvent ? formatISO(eventEnd, ISO_DATE_FORMAT) : null,
    dateTime: isAllDayEvent ? null : eventEnd.toISOString(),
  };
  const end = event_end || backupRawJsonEnd;
  const defaultStartTime = start_time_utc || eventStart.toISOString();
  const defaultEndTime = end_time_utc || eventEnd.toISOString();

  let event = {
    eventStart,
    eventEnd: isAllDayEvent ? eventStart : eventEnd,
    defaultStartTime,
    start_time_utc: defaultStartTime,
    defaultEndTime,
    end_time_utc: defaultEndTime,
    event_start: start,
    event_end: end,
    rbcEventEnd: protectMidnightCarryOver(eventEnd),
    user_calendar_id: calendarId,
    calendarId,
    hold_details: {
      ...holdDetails,
      isCreatingNonHoldEvent: true, // Add to prevent entering edit hold event logic in index.js (event form)
    },
    raw_json: {
      description,
      start,
      end,
      location,
      summary: title,
      attendees: eventAttendees,
    },
    keepAttendees: true,
    allDay: isAllDayEvent,
    displayAsAllDay: isAllDayEvent,
  };
  const conferenceData = determineConferencing();
  if (conferenceData) {
    event = addEventConferenceData(event, conferenceData);
  }

  return event;
}

/**
 * At some times we were saving all day timestamps with the `yyyy-M-d` format.
 * Other times we were saving them with the `yyyy-MM-dd` format.
 * Since both have been persisted to the DB, let's handle both for now, but start
 * standardizing to `yyyy-MM-dd` since it's behavior is more consistent.
 *
 * @example
 * new Date("2025-09-30")
 * // => Mon Sep 29 2025 20:00:00 GMT-0400 (Eastern Daylight Time)
 * new Date("2025-9-30")
 * // => Tue Sep 30 2025 00:00:00 GMT-0400 (Eastern Daylight Time)
 * new Date("2025-10-01")
 * // => Tue Sep 30 2025 20:00:00 GMT-0400 (Eastern Daylight Time)
 * new Date("2025-10-1")
 * // => Wed Oct 01 2025 00:00:00 GMT-0400 (Eastern Daylight Time)
 * new Date("2025-10-10")
 * // => Thu Oct 09 2025 20:00:00 GMT-0400 (Eastern Daylight Time)
 *
 * @param {string} dateString
 */
function fixAllDayStringFormat(dateString) {
  if (/^\d+-\d+-\d+$/.test(dateString)) {
    return dateString.split("-").map(part => part.padStart(2, "0")).join("-");
  }
  return dateString;
}

export function convertSlotsIntoISOString(slots, timeZone) {
  if (isEmptyArrayOrFalsey(slots)) {
    return [];
  }

  return slots.map((s) => {
    const isAllDay = isEventSlotAllDayEvent(s);
    if (isAllDay) {
      return {
        startDate: formatISOAllDayDate(s.eventStart),
        endDate: formatISOAllDayDate(s.eventEnd),
      };
    }
    return {
      start: getTimeInAnchorTimeZone(s.eventStart, timeZone).toISOString(),
      end: getTimeInAnchorTimeZone(s.eventEnd, timeZone).toISOString(),
    };
  });
}

export function removeDuplicateSlots(slotArray) {
  let uniqueSlots = [];
  let uniqueSlotsTracker = [];
  slotArray.forEach((s) => {
    const uniqueKey = createKeyFromSlot(s);
    if (uniqueSlotsTracker.includes(uniqueKey)) {
      return;
    }

    uniqueSlots = uniqueSlots.concat(s);
    uniqueSlotsTracker = uniqueSlotsTracker.concat(uniqueKey);
  });

  return uniqueSlots;
}

export function createGroupVoteTimeText(selectedSlots, format24HourTime) {
  if (isEmptyArray(selectedSlots)) {
    return "";
  }

  const selectedAvailabilitySlots = selectedSlots.filter(
    (event) => event.isAvailability
  );

  const availabilitySlots = immutablySortArray(selectedAvailabilitySlots, (a, b) =>
    sortEventsJSDate(a, b)
  );

  let availabilityString = "";
  let availableSlotObject = {};
  const getDateTimeFormatString = (slot) => {
    if (isMultiDaySlot(slot)) {
      return `${format(slot.eventStart, "MMMM d")} - ${format(
        subDays(slot.eventEnd, 1),
        "MMMM d"
      )}`;
    }
    return format(slot.eventStart, "MMMM d (EEEE)");
  }

  availabilitySlots.forEach((slot) => {
    const datePlusDayOfWeek = getDateTimeFormatString(slot);
    let eventStartTime;
    let eventEndTime;

    eventStartTime = slot.eventStart;
    eventEndTime = slot.eventEnd;

    if (datePlusDayOfWeek in availableSlotObject) {
      availableSlotObject[datePlusDayOfWeek] = [
        ...availableSlotObject[datePlusDayOfWeek],
        { eventStartTime, eventEndTime, ...slot },
      ];
    } else {
      availableSlotObject[datePlusDayOfWeek] = [
        { eventStartTime, eventEndTime, ...slot },
      ];
    }
  });

  Object.keys(availableSlotObject).forEach((date) => {
    availabilityString = availabilityString + date + "\n";

    // TODO: check pasting on everything (google doc, evernote, onenote, notion, email, etc) and online and offline things (without internet)
    availableSlotObject[date].forEach((slot) => {
      if (slot.displayAsAllDay) {
        availabilityString =
          availabilityString + "   " + "\u2022 " + "All day" + "\n";
        return;
      }
      availabilityString =
        availabilityString +
        "   " +
        "\u2022 " +
        format(slot.eventStartTime, getDateTimeFormat(format24HourTime)) +
        " - " +
        format(slot.eventEndTime, getDateTimeFormat(format24HourTime)) +
        "\n";
    });
  });

  return availabilityString;
}

export function isMultiDaySlot(slot) {
  return differenceInDays(slot.eventEnd, slot.eventStart) > 1;
}

export function isSlotInSelectSlots(slot, selectedSlots) {
  if (!slot.isAvailability) {
    return false;
  }

  return selectedSlots.some((s) => isSameSlot(slot, s));
}

export function getNonExpiredSelectedSlots(groupVoteLink) {
  if (isEmptyObjectOrFalsey(groupVoteLink)) {
    return [];
  }

  const { selected_slots, time_zone } = groupVoteLink;

  const jsDateArray = convertISOSlotsArrayToJSDate(selected_slots, time_zone);
  return filterEventsInThePast(jsDateArray);
}

export function getNonExpiredSelectedSlotsWithDefaultTimeZone(groupVoteLink) {
  const jsDateArray = parseEventsWithDefaultTimeZone(groupVoteLink);
  return filterEventsInThePast(jsDateArray);
}

export function parseEventsWithDefaultTimeZone(groupVoteLink, timeZone) {
  if (isEmptyObjectOrFalsey(groupVoteLink)) {
    return [];
  }
  const { selected_slots } = groupVoteLink;

  /**
   * @param {string} timeString
   * @returns {Date}
   */
  const parseTimeString = (timeString) => {
    const time = parseISO(timeString);

    if (timeZone && timeZone !== guessTimeZone()) {
      return convertToTimeZone(time, { timeZone });
    }

    return time;
  };

  const parsedSlots = selected_slots.map(s => {
    if (s.startDate && s.endDate) {
      return {
        eventStart: parseISO(fixAllDayStringFormat(s.startDate)),
        eventEnd: parseISO(fixAllDayStringFormat(s.endDate)),
        startDate: s.startDate,
        endDate: s.endDate,
      };
    }

    return {
      eventStart: parseTimeString(s.start),
      eventEnd: parseTimeString(s.end),
      start: s.start,
      end: s.end,
    };
  });

  const currentTime = new Date();
  const filtered = parsedSlots.filter(slot => isAfterMinute(slot.eventEnd, currentTime)); // filter out expired slots;
  if (isEmptyArray(filtered)) {
    return parsedSlots;
  }
  return filtered;
}

function getEventStartAndEndFromKey(key) {
  if (!key) {
    return { eventStart: null, eventEnd: null };
  }

  const startEndArray = key.split("_");
  if (startEndArray.length <= 1) {
    return { eventStart: null, eventEnd: null };
  }

  return {
    eventStart: parseISO(startEndArray[0]),
    eventEnd: parseISO(startEndArray[1]),
  };
}

export function sortSlotsChronologically(slots) {
  if (isEmptyArrayOrFalsey(slots)) {
    return [];
  }

  return immutablySortArray(slots.slice(), (a, b) => sortEventsJSDate(a, b));
}

function mergeAttendeeIndex({ slotAttendeeIndex, slotCriticalAttendeeIndex }) {
  let mergedAttendeeIndex = {};
  const allKeys = [...Object.keys(slotAttendeeIndex), ...Object.keys(slotCriticalAttendeeIndex)];

  allKeys.forEach(key => {
    const attendeeIndexValue = slotAttendeeIndex[key];
    const criticalAttendeIndexValue = slotCriticalAttendeeIndex[key];

    mergedAttendeeIndex[key] = {
      ...(isTypeObject(mergedAttendeeIndex[key]) ? mergedAttendeeIndex[key] : {}),
      nonCriticalCount: !isEmptyArrayOrFalsey(attendeeIndexValue) ? attendeeIndexValue.length : 0,
    };
    mergedAttendeeIndex[key] = {
      ...(isTypeObject(mergedAttendeeIndex[key]) ? mergedAttendeeIndex[key] : {}),
      criticalCount: !isEmptyArrayOrFalsey(criticalAttendeIndexValue) ? criticalAttendeIndexValue.length : 0,
    };
  });

  return mergedAttendeeIndex;
}

function isSlotIndexCriticalCountNumber({ attendeeIndex, keyA, keyB }) {
  return isTypeNumber(attendeeIndex[keyA]?.criticalCount) && isTypeNumber(attendeeIndex[keyB]?.criticalCount);
}

function isSlotIndexNonCriticalCountNumber({ attendeeIndex, keyA, keyB }) {
  return isTypeNumber(attendeeIndex[keyA]?.nonCriticalCount) && isTypeNumber(attendeeIndex[keyB]?.nonCriticalCount);
}

export function sortSlotsByAttendeeVoteISO({a, b, slotAttendeeIndex, slotCriticalAttendeeIndex}) {
  const keyA = createKeyFromSlotISOString(a);
  const keyB = createKeyFromSlotISOString(b);
  const indexToUse = mergeAttendeeIndex({ slotAttendeeIndex, slotCriticalAttendeeIndex });

  /* Prioritize critical attendees */
  if (isSlotIndexCriticalCountNumber({ attendeeIndex: indexToUse, keyA, keyB })) {
    const criticalCountA = indexToUse[keyA].criticalCount;
    const criticalCountB = indexToUse[keyB].criticalCount;

    if (criticalCountA > criticalCountB) {
      return -1;
    }

    if (criticalCountA < criticalCountB) {
      return 1;
    }

    /* Don't sort if critical attendee count is the same */
    /* Allow secondary logic to take over */
  }

  /* Non critical attendees secondary */
  if (isSlotIndexNonCriticalCountNumber({ attendeeIndex: indexToUse, keyA, keyB })) {
    const nonCriticalCountA = indexToUse[keyA].nonCriticalCount;
    const nonCriticalCountB = indexToUse[keyB].nonCriticalCount;

    if (nonCriticalCountA > nonCriticalCountB) {
      return -1;
    }

    if (nonCriticalCountA < nonCriticalCountB) {
      return 1;
    }

    if (nonCriticalCountA == nonCriticalCountB) {
      return isBeforeMinute(a.eventStart, b.eventStart) ? -1 : 1;
    }
  }

  /* Key is missing in one or more index */
  if (indexToUse[keyA] && !indexToUse[keyB]) {
    return -1;
  } else if (!indexToUse[keyA] && indexToUse[keyB]) {
    return 1;
  } else if (isBeforeMinute(a.eventStart, b.eventStart)) {
    return -1;
  } else if (isAfterMinute(a.eventStart, b.eventStart)) {
    return 1;
  } else if (isSameMinute(a.eventStart, b.eventStart)) {
    return isBeforeMinute(a.eventEnd, b.eventEnd) ? -1 : 1;
  }

  return keyA < keyB ? 1 : -1;
}

export function sortSlotsByAttendeeVote({a, b, slotAttendeeIndex, slotCriticalAttendeeIndex, selectedTimeZone}) {
  const keyA = createKeyFromSlot(a, selectedTimeZone);
  const keyB = createKeyFromSlot(b, selectedTimeZone);
  const indexToUse = mergeAttendeeIndex({ slotAttendeeIndex, slotCriticalAttendeeIndex });

  /* Prioritize critical attendees */
  if (isSlotIndexCriticalCountNumber({ attendeeIndex: indexToUse, keyA, keyB })) {
    const criticalCountA = indexToUse[keyA].criticalCount;
    const criticalCountB = indexToUse[keyB].criticalCount;

    if (criticalCountA > criticalCountB) {
      return -1;
    }

    if (criticalCountA < criticalCountB) {
      return 1;
    }

    /* Don't sort if critical attendee count is the same */
    /* Allow secondary logic to take over */
  }

  /* Non critical attendees secondary */
  if (isSlotIndexNonCriticalCountNumber({ attendeeIndex: indexToUse, keyA, keyB })) {
    const nonCriticalCountA = indexToUse[keyA].nonCriticalCount;
    const nonCriticalCountB = indexToUse[keyB].nonCriticalCount;

    if (nonCriticalCountA > nonCriticalCountB) {
      return -1;
    }

    if (nonCriticalCountA < nonCriticalCountB) {
      return 1;
    }

    if (nonCriticalCountA == nonCriticalCountB) {
      return isBeforeMinute(a.eventStart, b.eventStart) ? -1 : 1;
    }
  }

  /* Key is missing in one or more index */
  if (indexToUse[keyA] && !indexToUse[keyB]) {
    return -1;
  } else if (!indexToUse[keyA] && indexToUse[keyB]) {
    return 1;
  } else if (isBeforeMinute(a.eventStart, b.eventStart)) {
    return -1;
  } else if (isAfterMinute(a.eventStart, b.eventStart)) {
    return 1;
  } else if (isSameMinute(a.eventStart, b.eventStart)) {
    return isBeforeMinute(a.eventEnd, b.eventEnd) ? -1 : 1;
  }

  return keyA < keyB ? 1 : -1;
}

// lastSelectedSlotsStyle is from appSettings
export function determineSlotsSelectType({
  availabilitySettings,
  lastSelectedSlotsStyle,
  masterAccount,
  currentUser,
}) {
  if (!isNullOrUndefined(lastSelectedSlotsStyle)) {
    return getSlotSelectOption(lastSelectedSlotsStyle);
  }
  if (isUserMaestroUser(masterAccount)) {
    const createdAtTime = getMasterAccountCreatedAtJSDate(masterAccount);
    if (shouldShowSlotsTextFormat(currentUser) && isValidJSDate(createdAtTime) && getYear(createdAtTime) >= 2025) {
      return SLOTS_SELECT_TYPE_PLAIN_TEXT;
    }
  }

  if (isEmptyObjectOrFalsey(availabilitySettings)) {
    return SLOTS_SELECT_TYPE_HYPER_LINKED;
  }

  return getSlotSelectOption(availabilitySettings[SLOTS_STYLE]);
}

function getSlotSelectOption(style) {
  switch (style) {
    case SLOTS_PLAIN_TEXT:
      return SLOTS_SELECT_TYPE_PLAIN_TEXT;
    case SLOTS_PLAIN_TEXT_URL:
      return SLOTS_SELECT_TYPE_TEXT_URL;
    case SLOTS_RICH_TEXT:
      return SLOTS_SELECT_TYPE_HYPER_LINKED;
    case LINK_ALONE:
      return SLOTS_LINK_ALONE;
    default:
      return SLOTS_SELECT_TYPE_HYPER_LINKED;
  }
}

export function getDefaultSlotsCopy(value, availabilitySettings) {
  const copies = getAvailabilitySlotCopy(availabilitySettings);
  switch (value) {
    case SLOTS_PLAIN_TEXT:
      return { preSlotsCopy: copies[SLOTS_PLAIN_TEXT_COPY] };
    case SLOTS_PLAIN_TEXT_URL:
      return {
        preSlotsCopy: copies[SLOTS_PLAIN_TEXT_URL_COPY],
        linkCopy: copies[SLOTS_PLAIN_TEXT_URL_LINK_COPY],
      };
    case SLOTS_RICH_TEXT:
      return { preSlotsCopy: copies[SLOTS_RICH_TEXT_COPY] };
    default:
      return {
        preSlotsCopy: copies[SLOTS_PLAIN_TEXT_URL_COPY],
        linkCopy: copies[SLOTS_PLAIN_TEXT_URL_LINK_COPY],
      };
  }
}

function getAvailabilitySlotCopy(availabilitySettings) {
  // add in defaults incase the data is empty
  if (isEmptyObjectOrFalsey(availabilitySettings)) {
    return getDefaultSlotCopies();
  }

  return {
    [SLOTS_PLAIN_TEXT_COPY]:
      availabilitySettings[SLOTS_PLAIN_TEXT_COPY] ?? DEFAULT_PRE_SLOTS_COPY,
    [SLOTS_PLAIN_TEXT_URL_COPY]:
      availabilitySettings[SLOTS_PLAIN_TEXT_URL_COPY] ?? DEFAULT_PRE_SLOTS_COPY,
    [SLOTS_PLAIN_TEXT_URL_LINK_COPY]:
      availabilitySettings[SLOTS_PLAIN_TEXT_URL_LINK_COPY] ?? DEFAULT_LINK_COPY,
    [SLOTS_RICH_TEXT_COPY]:
      availabilitySettings[SLOTS_RICH_TEXT_COPY] ??
      `${DEFAULT_PRE_SLOTS_COPY} ${DEFAULT_LINK_COPY}`,
  };
}

function getDefaultSlotCopies() {
  return {
    [SLOTS_PLAIN_TEXT_COPY]: DEFAULT_PRE_SLOTS_COPY,
    [SLOTS_PLAIN_TEXT_URL_COPY]: DEFAULT_PRE_SLOTS_COPY,
    [SLOTS_PLAIN_TEXT_URL_LINK_COPY]: DEFAULT_LINK_COPY,
    [SLOTS_RICH_TEXT_COPY]: `${DEFAULT_PRE_SLOTS_COPY} ${DEFAULT_LINK_COPY}`,
  };
}

export function getBufferDaysHoursMinutesFromMinutes(inputBufferFromNow) {
  const timeInMinutesDefault = inputBufferFromNow ?? 0;
  const days = Math.floor(timeInMinutesDefault / 24 / 60);
  const hours = Math.floor((timeInMinutesDefault / 60) % 24);
  const minutes = timeInMinutesDefault % 60;

  return {
    days,
    hours,
    minutes,
  };
}

export function convertBufferDaysHoursMinutesToMinutes(bufferFromNow) {
  // Check if buffer exists and contains only integers
  if (
    isEmptyObjectOrFalsey(bufferFromNow) ||
    !Number.isInteger(bufferFromNow.days) ||
    !Number.isInteger(bufferFromNow.hours) ||
    !Number.isInteger(bufferFromNow.minutes)
  ) {
    return 0;
  }
  const { days, hours, minutes } = bufferFromNow;

  return days * 24 * 60 + hours * 60 + minutes;
}

export function sortBlockedCalendars(blockedCalendarsList) {
  return immutablySortArray(blockedCalendarsList, (a, b) => {
    const calendarAEmail = getCalendarEmail(a);
    const calendarBEmail = getCalendarEmail(b);

    if (calendarAEmail < calendarBEmail) {
      return -1;
    } else if (calendarAEmail > calendarBEmail) {
      return 1;
    }
    return 0;
  });
}

export function sortGroupVote(groupVotes) {
  if (isEmptyArrayOrFalsey(groupVotes)) {
    return [];
  }

  const groupVoteExpiredCache = {}; // token (unique): isExpired
  groupVotes.forEach(groupVote => {
    groupVoteExpiredCache[getGroupVoteToken(groupVote)] = isGroupVoteLinkExpired(groupVote);
  });

  return immutablySortArray(groupVotes, (a, b) => {
    if (groupVoteExpiredCache[getGroupVoteToken(a)] && !groupVoteExpiredCache[getGroupVoteToken(b)]) {
      return 1;
    } else if (!groupVoteExpiredCache[getGroupVoteToken(a)] && groupVoteExpiredCache[getGroupVoteToken(b)]) {
      return -1;
    } else if (a.title > b.title) {
      return 1;
    } else if (a.title < b.title) {
      return -1;
    }

    return 0;
  });
}

export function getBlockedCalendarList(blockedCalendars) {
  if (isEmptyArray(blockedCalendars)) {
    return [];
  }

  let allBlockedCalendars = [];

  blockedCalendars.forEach((blockedCalendarsByUser) => {
    // need to wrap in object because that's how allCalendars are set up and thus all function use .calendar methodology
    const mainCalendars = blockedCalendarsByUser.calendars.map((c) => {
      return { calendar: c };
    });
    allBlockedCalendars = allBlockedCalendars.concat(mainCalendars);
  });

  return sortBlockedCalendars(allBlockedCalendars);
}

export function getBlockedCalendarsID(blockedCalendars) {
  return getBlockedCalendarList(blockedCalendars).map((c) =>
    getCalendarUserCalendarID(c)
  );
}

export function getBlockingCalendarsPersonalLink(personalLink) {
  return personalLink?.blocked_calendars;
}

export function getGroupVoteDurationList() {
  return createLabelAndValueForReactSelect([
    10, 15, 20, 25, 30, 45, 50, 60, 90, 120,
  ]);
}

export function getDefaultMinuteListForReactSelect(includeZero = false) {
  if (includeZero) {
    return createLabelAndValueForReactSelect([
      0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60,
    ]);
  }

  return createLabelAndValueForReactSelect([
    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60,
  ]);
}

function getLastSlotDurationKey(currentUser) {
  return `${currentUser?.email}_lastSlotDuration`;
}

export function saveLastSlotDuration(currentUser, duration) {
  if (!currentUser) {
    return null;
  }

  localData(
    LOCAL_DATA_ACTION.SET,
    getLastSlotDurationKey(currentUser),
    duration
  );
}

export function getLastSlotDuration(currentUser) {
  if (!currentUser) {
    return null;
  }

  return localData(LOCAL_DATA_ACTION.GET, getLastSlotDurationKey(currentUser));
}

export function combineSlots(slots) {
  let combinedSlots = [];

  // filter out allDay events and then filter it back in
  const allDaySlots = slots.filter((s) => isEventSlotAllDayEvent(s));
  const noneAllDaySlots = slots.filter((s) => !isEventSlotAllDayEvent(s));
  const sortedSlots = sortTemporaryAvailabilitySlots(noneAllDaySlots);

  sortedSlots.forEach((s) => {
    combinedSlots = addSlotIntoCombinedSlot(s, combinedSlots);
  });

  return combinedSlots.concat(allDaySlots);
}

function addSlotIntoCombinedSlot(slot, sortedCombinedSlots) {
  // given an array of combined slots, see if slot is part of the combinedSlot or should be concatted onto the combined slot
  if (sortedCombinedSlots.length === 0) {
    return [slot];
  } else if (!slot || slot.length === 0) {
    return sortedCombinedSlots;
  }

  let updatedSlots = sortedCombinedSlots;
  const lastSlotPosition = updatedSlots.length - 1;
  const lastSlot = updatedSlots[lastSlotPosition];

  if (
    isSameOrBeforeMinute(slot.eventStart, lastSlot.eventStart) &&
    isSameOrAfterMinute(slot.eventEnd, lastSlot.eventEnd)
  ) {
    // lastSlot: |----|
    // slot:     |---------------|
    updatedSlots[lastSlotPosition] = slot;
  } else if (
    isSameOrBeforeMinute(lastSlot.eventStart, slot.eventStart) &&
    isSameOrAfterMinute(lastSlot.eventEnd, slot.eventEnd)
  ) {
    // lastSlot:   |----------------|
    // slot:            |-------|
    // do nothing
  } else if (
    isSameOrBeforeMinute(slot.eventStart, lastSlot.eventStart) &&
    isSameOrBeforeMinute(lastSlot.eventStart, slot.eventEnd) &&
    isSameOrAfterMinute(lastSlot.eventEnd, slot.eventEnd)
  ) {
    // lastSlot:        |--------|
    // slot:        |-------|
    updatedSlots[lastSlotPosition] = createTemporaryEvent({
      startTime: slot.eventStart,
      endTime: lastSlot.eventEnd,
      index: lastSlot.index,
      isGroupVote: false,
    });
  } else if (
    isSameOrBeforeMinute(lastSlot.eventStart, slot.eventStart) &&
    isSameOrBeforeMinute(slot.eventStart, lastSlot.eventEnd) &&
    isSameOrAfterMinute(slot.eventEnd, lastSlot.eventEnd)
  ) {
    // lastSlot: |--------|
    // slot:          |-------|
    updatedSlots[lastSlotPosition] = createTemporaryEvent({
      startTime: lastSlot.eventStart,
      endTime: slot.eventEnd,
      index: lastSlot.index,
      isGroupVote: false,
    });
  } else {
    // outside:
    // lastSlot: |-----|
    // slot:                  |-----|
    // do nothing, will add at the end
    updatedSlots = updatedSlots.concat(slot);
  }

  return updatedSlots;
}

function sortTemporaryAvailabilitySlots(slots) {
  if (isEmptyArrayOrFalsey(slots)) {
    return [];
  }

  return immutablySortArray(slots, (a, b) => {
    if (isBeforeMinute(a.eventStart, b.eventStart)) {
      return -1; // a before b
    } else if (isSameMinute(a.eventStart, b.eventStart)) {
      // break tie on end
      if (!a.index || a.index < b.index) {
        return -1; // a before b
      } else {
        return 1; // b before a
      }
    } else {
      return 1; // b before a
    }
  });
}

export function splitSlotIntoDuration({
  breakDuration,
  start, // updatedStart
  end, //final end
  currentSlots,
  hideCancel,
  isTemporaryAIEvent,
  breakIntoInterval = false, // this is for slots where we need to break it down by either 15 or 30 min (interval) instead fo the break duration
  isGroupVote = false,
  type, // TEMPORARY_EVENT_TYPES
  currentUserEmail,
}) {
  // To break into multiple slots
  const roundToNumber = shouldRoundToNearest15(breakDuration) ? 15 : 30;

  const roundedStart = RoundToClosestMinuteJSDate(start, roundToNumber);
  let firstStart = roundedStart;
  let endTracker = addMinutes(
    roundedStart,
    breakIntoInterval ? roundToNumber : breakDuration
  );
  const firstEvent =
    type === TEMPORARY_EVENT_TYPES.CREATE_EVENT_FIND_TIME
      ? createEventFormFindTimeTemporaryEvent({
          startTime: roundedStart,
          endTime: addMinutes(roundedStart, breakDuration),
          index: currentSlots.length,
          currentUserEmail,
        })
      : createTemporaryEvent({
          startTime: roundedStart,
          endTime: addMinutes(roundedStart, breakDuration), // this should always be break duration
          index: currentSlots.length,
          hideCancel,
          isTemporaryAIEvent,
          isGroupVote: isGroupVote,
        });
  let newAvailableSlots = [firstEvent];

  while (
    isSameOrBeforeMinute(
      addMinutes(
        RoundToClosestMinuteJSDate(endTracker, roundToNumber),
        breakIntoInterval ? roundToNumber : breakDuration
      ),
      end
    )
  ) {
    firstStart = RoundToClosestMinuteJSDate(endTracker, roundToNumber);
    endTracker = addMinutes(
      firstStart,
      breakIntoInterval ? roundToNumber : breakDuration
    );
    const event =
      type === TEMPORARY_EVENT_TYPES.CREATE_EVENT_FIND_TIME
        ? createEventFormFindTimeTemporaryEvent({
            startTime: firstStart,
            endTime: addMinutes(firstStart, breakDuration),
            index: currentSlots.length + newAvailableSlots.length,
            currentUserEmail,
          })
        : createTemporaryEvent({
            startTime: firstStart,
            endTime: addMinutes(firstStart, breakDuration), // this should always be break duration
            index: currentSlots.length + newAvailableSlots.length,
            hideCancel,
            isTemporaryAIEvent,
            isGroupVote: isGroupVote,
          });
    newAvailableSlots = newAvailableSlots.concat(event);
  }

  return newAvailableSlots;
}

// personal links hot key index start with 1 instead of 0
export function getPersonalLinkHotKeyIndex(inputIndex) {
  if (inputIndex === 0) {
    return 9;
  } else {
    return inputIndex - 1;
  }
}

export function getSlotsPath() {
  return isVersionV2() ? "slots" : "availabilities";
}

export function createTimeZoneAvailabilityLine({
  slot,
  timeZone,
  format24HourTime,
  currentTimeZone,
  firstTimeZone,
  date,
}) {
  const { eventStart, eventEnd } = slot;
  const updatedStart = getTimeInAnchorTimeZone(
    eventStart,
    currentTimeZone,
    timeZone
  );
  const firstTimeZoneEventStart = firstTimeZone
    ? getTimeInAnchorTimeZone(eventStart, currentTimeZone, firstTimeZone)
    : null;
  const updatedEnd = getTimeInAnchorTimeZone(
    eventEnd,
    currentTimeZone,
    timeZone
  );
  const getNextDayText = () => {
    const isNextDay = isAfterDay(updatedStart, firstTimeZoneEventStart);
    return isNextDay ? " +1" : "";
  };
  // return 8:30am - 5:30pm (ET) / 5:30am - 2:30pm (PT)
  return (
    formatSlotsTextForTime({ time: updatedStart, format24HourTime }) +
    " - " +
    formatSlotsTextForTime({ time: updatedEnd, format24HourTime }) +
    ` (${createAbbreviationForTimeZone(timeZone, date)})` +
    getNextDayText()
  );
}

export function getListOfTimeZoneAbbreviationsForSlotsHeader({
  timeZones,
  date,
}) {
  const sortedTimeZones = sortMultipleTimeZonesForSlots({ timeZones });
  return sortedTimeZones
    .map((timeZone) => {
      return createAbbreviationForTimeZone(timeZone, date);
    })
    .join("/");
}

export function sortMultipleTimeZonesForSlots({ timeZones }) {
  const sortedTimeZones = immutablySortArray(timeZones, (a, b) => {
    const aOffset = getUtcOffset(a);
    const bOffset = getUtcOffset(b);
    return aOffset - bOffset;
  });

  return sortedTimeZones;
}

export function formatSlotsTextForTime({ time, format24HourTime }) {
  if (format24HourTime) {
    return format(time, DATE_TIME_24_HOUR_FORMAT);
  }

  const minute = getMinutes(time);
  if (minute === 0) {
    return format(time, "haaa");
  }
  return format(time, "h:mmaaa");
}

export async function fetchPersonalLinks(user) {
  if (isEmptyObjectOrFalsey(user)) {
    return;
  }

  const path = "personal_links";
  const url = constructRequestURL(path, isVersionV2());

  return Fetcher.get(url, {}, true, getUserEmail(user))
    .then((response) => {
      if (isEmptyObjectOrFalsey(response) || response.error) {
        return;
      }

      return response.personal_links ?? response.personal_link;
    })
    .catch(handleError);
}

export async function fetchFreeBusySlots({
  currentUser,
  conferencing,
  bookableSlots,
  blockedCalendars,
  blockedAttendeeEmails, // list of emails (e.g. ["seamus@vimcal.com", "mike@vimcal.com"])
  isIgnoreInternalConflicts,
}) {
  if (isEmptyObjectOrFalsey(currentUser)) {
    return null;
  }

  const path = "calendars/free_busy";
  const url = constructRequestURL(path, isVersionV2());
  const linkData = {
    conferencing,
    time_slots: bookableSlots,
    blocked_calendar_ids: blockedCalendars,
  };
  if (blockedAttendeeEmails?.length > 0) {
    linkData.blocked_attendee_emails = blockedAttendeeEmails.map((email) =>
      lowerCaseAndTrimString(email)
    );
  }
  if (isIgnoreInternalConflicts) {
    linkData[BACKEND_IGNORE_INTERNAL_CONFLICTS_KEY] = true;
  }

  const payloadData = {
    headers: getDefaultHeaders(),
    body: JSON.stringify(linkData),
  };

  try {
    return await Fetcher.post(url, payloadData, true, getUserEmail(currentUser));
  } catch (error) {
    handleError(error);
    return null;
  }
}

export function isAllowedBookingLink(link) {
  if (!link) {
    return false;
  }

  const strippedLink = formatBookingLinkText(link);
  if (!isUrl(strippedLink)) {
    return false;
  }
  return (
    strippedLink.includes("vimcal.com") || strippedLink.includes("calendly.com")
  );
}

export function formatBookingLinkText(link) {
  if (!link) {
    return "";
  }
  return isHTMLText(link)
    ? removeSpecialHTMLCharacters(link)
    : link.toLowerCase().trim();
}

export function getParsableBookingLinkData(link) {
  const loweredCaseTrimmed = formatBookingLinkText(link);
  if (loweredCaseTrimmed.includes("vimcal.com")) {
    backendBroadcasts.publish("GET_VIMCAL_LINK_DATA", loweredCaseTrimmed);
  } else if (loweredCaseTrimmed.includes("calendly.com")) {
    backendBroadcasts.publish("GET_CALENDLY_LINK_DATA", {calendlyURL: loweredCaseTrimmed});
  }
}

export const SLOTS_SPAN_TYPE = {
  MORNING: "morning",
  AFTERNOON: "afternoon",
  NEXT_WEEK: "next_week",
  ONLY_THIS_CURRENT_WEEK: "only_this_week",
  ONLY_WEEK_IN_VIEW: "week_in_view",
  RANDOM: "random",
};

export const SLOTS_SPAN_HOTKEY = {
  MORNING: "m",
  AFTERNOON: "a",
  NEXT_WEEK: "n",
  ONLY_THIS_CURRENT_WEEK: "c",
  ONLY_WEEK_IN_VIEW: "w",
  RANDOM: "r",
};

const MAX_DURATION_LIMIT_IN_MIN = 120; // 2 hours
const SLICE_LIMIT = 4; // how many elements to return
export function grabAvailableSlots({
  spanType,
  freeSlots,
  currentTimeZone,
  duration,
  weekStart,
  currentDate = new Date(),
}) {
  if (isEmptyArray(freeSlots)) {
    return [];
  }

  const localTimeZone = guessTimeZone();
  const maxDuration =
    duration > MAX_DURATION_LIMIT_IN_MIN ? duration : MAX_DURATION_LIMIT_IN_MIN;
  const parsedSlots = freeSlots.map((slot) => {
    const {
      end_time, // e.g. 2023-06-09T13:00:00.000Z
      start_time,
    } = slot;
    return {
      eventStart: getTimeInAnchorTimeZone(
        parseISO(start_time),
        localTimeZone,
        currentTimeZone
      ),
      eventEnd: getTimeInAnchorTimeZone(
        parseISO(end_time),
        localTimeZone,
        currentTimeZone
      ),
    };
  });
  let slicedIntoMaxDuration = [];
  parsedSlots.forEach((e) => {
    const { eventStart, eventEnd } = e;
    if (differenceInMinutes(eventEnd, eventStart) <= maxDuration) {
      slicedIntoMaxDuration = slicedIntoMaxDuration.concat(
        createTemporaryEvent({
          startTime: eventStart,
          endTime: eventEnd, // this should always be break duration
          index: slicedIntoMaxDuration.length,
        })
      );
      return;
    }

    const newSlots = splitSlotIntoDuration({
      breakDuration: maxDuration,
      start: eventStart,
      end: eventEnd,
      currentSlots: slicedIntoMaxDuration,
    });

    slicedIntoMaxDuration = slicedIntoMaxDuration.concat(newSlots);
  });

  // to get rid of all the other unused info in each slot object
  slicedIntoMaxDuration = immutablySortArray(slicedIntoMaxDuration
    .map((slot) => {
      const { eventStart, eventEnd } = slot;
      return { eventStart, eventEnd };
    }), (a, b) => sortEventsJSDate(a, b));

  let filteredSlots = [];
  switch (spanType) {
    case SLOTS_SPAN_TYPE.MORNING:
      filteredSlots = getRandomAvailableSlots(
        getMorningSlots(slicedIntoMaxDuration)
      );
      break;
    case SLOTS_SPAN_TYPE.AFTERNOON:
      filteredSlots = getRandomAvailableSlots(
        getAfterSlots(slicedIntoMaxDuration)
      );
      break;
    case SLOTS_SPAN_TYPE.NEXT_WEEK:
      filteredSlots = getRandomAvailableSlots(
        getNextWeekSlots(slicedIntoMaxDuration, currentDate)
      );
      break;
    case SLOTS_SPAN_TYPE.ONLY_WEEK_IN_VIEW:
      filteredSlots = getRandomAvailableSlots(
        getThisWeekSlots({
          slots: slicedIntoMaxDuration,
          weekStart,
          currentDate,
        })
      );
      break;
    case SLOTS_SPAN_TYPE.RANDOM:
      filteredSlots = getRandomAvailableSlots(slicedIntoMaxDuration);
      break;
    case SLOTS_SPAN_TYPE.ONLY_THIS_CURRENT_WEEK:
      filteredSlots = getRandomAvailableSlots(
        getThisWeekSlots({
          slots: slicedIntoMaxDuration,
          weekStart,
          currentDate,
        })
      );
      break;
    default:
      break;
  }
  return immutablySortArray(filteredSlots, (a, b) => sortEventsJSDate(a, b)); // sort as a precaution
}

function getMorningSlots(slots) {
  return slots.filter((slot) => {
    const { eventStart } = slot;
    const hour = getHours(eventStart);
    return hour >= 8 && hour <= 12;
  });
}

function getAfterSlots(slots) {
  return slots.filter((slot) => {
    const { eventStart } = slot;
    const hour = getHours(eventStart);
    return hour >= 13 && hour <= 17;
  });
}

function getThisWeekSlots({ slots, weekStart, currentDate = new Date() }) {
  const weekStartsOn = isValidWeekStart(weekStart) ? parseInt(weekStart) : 0;
  return slots.filter((slot) => {
    const { eventStart } = slot;
    return isSameWeek(eventStart, currentDate, { weekStartsOn });
  });
}

function getNextWeekSlots(slots, currentDate = new Date()) {
  const friday = endOfDay(addDays(currentDate, 4));
  return slots.filter((slot) => {
    const { eventStart } = slot;
    return (
      isSameOrAfterMinute(eventStart, currentDate) &&
      isSameOrBeforeMinute(eventStart, friday)
    );
  });
}

// there as to be some randomness to the slots
// per day, get up to SLICE_LIMIT slots
// prioitize for this week
function getRandomAvailableSlots(slots) {
  return getRandomElements(slots, SLICE_LIMIT);
}

export function getActiveGroupVotes(groupVoteLinks) {
  if (isEmptyArray(groupVoteLinks)) {
    return [];
  }

  return groupVoteLinks.filter((gv) => !isGroupVoteLinkExpired(gv));
}

// variable should be of type ALL_BOOKING_VARIABLES
export function getHumanReadableBookingVariable(variable) {
  switch (variable) {
    case ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK:
      return "Invitee name";
    case ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME:
      return "Company name";
    default:
      return variable;
  }
}

export function removeBookingLinkBlockBrackets(variable) {
  return variable?.replaceAll("{{", "")?.replaceAll("}}", "") ?? "";
}

// we're always going to pass in invitee_name_block as a variable
// this gives us everything else
export function getAllExtraBookingVariables({ user, customQuestions }) {
  let extraVariables = Object.values(ALL_BOOKING_VARIABLES);
  if (
    !doesListOfQuestionsIncludeCompany(customQuestions) &&
    !shouldShowCompanyQuestion(user)
  ) {
    extraVariables = extraVariables.filter(
      (variable) => variable !== ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME
    );
  }
  return extraVariables.filter(
    (variable) => variable !== ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK
  );
}

export function doesListOfQuestionsIncludeCompany(questions) {
  const customQuestion = questions?.find((question) =>
    isCustomQuestionCompany(question)
  );
  if (!isEmptyObjectOrFalsey(customQuestion)) {
    return true;
  }
  return false;
}

export function isCustomQuestionCompany(question) {
  return question?.description === ALL_DEFAULT_CUSTOM_QUESTIONS.COMPANY;
}

// show if company question is asked
export function shouldShowCompanyQuestion(user) {
  return (
    user?.availability_settings?.custom_questions?.find((question) =>
      isCustomQuestionCompany(question)
    ) ?? false
  );
}

export function doesTitleIncludeCompanyQuestion(title) {
  return title?.includes(ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME);
}

export function isSameQuestion(questionA, questionB) {
  return (
    questionA?.type === questionB?.type &&
    questionA?.description === questionB?.description &&
    questionA?.required === questionB?.required
  );
}

// given list of userCalendarIDs -> get free availability
export function getFreeTimesGivenBusySlots({
  masterAccount,
  user, // get user from cmd j,
  currentDate, // should just be selected day
  currentTimeZone,
  duration,
  busySlots,
  filterForFutureOnly = true,
  weekStart,
  selectedCalendarView,
  workHours
}) {
  const now = getCurrentTimeInCurrentTimeZone(currentTimeZone);
  const getStartDateAndDaysForward = () => {
    const weekStartObj = { weekStartsOn: weekStart ? parseInt(weekStart) : 0 };
    if (selectedCalendarView === 7) {
      // week view
      return {
        daysForward: 14,
        startDate: startOfWeek(currentDate, weekStartObj),
      };
    } else if (selectedCalendarView === BACKEND_MONTH) {
      // month
      return {
        daysForward: 32,
        startDate: startOfMonth(currentDate, weekStartObj),
      };
    } else if (selectedCalendarView === 1) {
      // day view
      return { daysForward: 14, startDate: currentDate };
    } else if (selectedCalendarView === 4) {
      // 4 day view
      return { daysForward: 14, startDate: currentDate };
    } else if (selectedCalendarView === ROLLING_SEVEN_DAY) {
      // rolling 7 day
      return { daysForward: 14, startDate: currentDate };
    }
    const isAfterCurrentDate = isAfterDay(currentDate, now);
    const date = isAfterCurrentDate
      ? startOfWeek(currentDate, weekStartObj)
      : currentDate;
    return { daysForward: 14, startDate: date };
  };
  const workHoursWeekdaySlots = getDefaultWeekWithWorkHours({
    workHours: workHours ?? getWorkHours({ masterAccount, user }),
  });

  const { daysForward, startDate } = getStartDateAndDaysForward();
  const allFreeSlots = generateFreeSlotsFromBusyEvents({
    busySlots,
    slots: workHoursWeekdaySlots,
    daysForward,
    durationMinutes: duration,
    timeZone: currentTimeZone,
    bufferBefore: 0,
    bufferAfter: 0,
    currentDate: startDate,
    currentTimeZone,
  });
  if (filterForFutureOnly) {
    return allFreeSlots.filter((slot) =>
      isAfterMinute(
        convertToTimeZone(parseISO(slot.start_time), {
          timeZone: currentTimeZone,
        }),
        now
      )
    );
  }
  return allFreeSlots;
}

export function getGroupVoteURLLink({ userName, slug, token, isSpreadsheet }) {
  if (slug && userName) {
    return determineGroupVoteURL(`${userName}/${slug}`, isSpreadsheet);
  }
  return determineGroupVoteURL(token, isSpreadsheet);
}

export function getMeetWithEventsWithRawTimes(meetWithEvents) {
  if (isEmptyArray(meetWithEvents)) {
    return [];
  }

  return (
    meetWithEvents
      .filter(event => isBusyEvent(event))
      .map((event) => {
        const { defaultStartTime, defaultEndTime } = event;
        return {
          start: defaultStartTime,
          end: defaultEndTime,
        };
      }) ?? []
  );
}

function generateFreeSlotsFromBusyEvents({
  busySlots = [],
  slots,
  daysForward,
  durationMinutes,
  timeZone,
  bufferBefore = 0,
  bufferAfter = 0,
  currentDate = new Date(),
  upcomingTrips,
  currentTimeZone,
}) {
  let freeSlots = [];
  let roundUpInterval = shouldRoundToNearest15(durationMinutes) ? 15 : 30;
  let roundDownInterval = 15;

  // generateBookableSlotsFromObj -> array as utc
  // {
  //   "start_time": "2024-01-22T08:00:00.000Z",
  //   "end_time": "2024-01-22T16:00:00.000Z",
  //   "index": 0
  // }
  // rawDayOfWeekSlots returns array of object in iso format
  const rawDayOfWeekSlots = generateBookableSlotsFromObj({
    slots,
    timeZone,
    daysForward,
    bufferBefore,
    bufferAfter,
    currentDate,
    upcomingTrips,
  });
  const dayOfWeekSlots = rawDayOfWeekSlots.map((slot) => {
    return {
      ...slot,
      start_time: convertToTimeZone(parseISO(slot.start_time), {
        timeZone: currentTimeZone,
      }),
      end_time: convertToTimeZone(parseISO(slot.end_time), {
        timeZone: currentTimeZone,
      }),
    };
  });

  dayOfWeekSlots.forEach((s) => {
    // function below is the problem
    // freeSlot is {start_time: JSDate, end_time: JSDate}
    const freeSlot = createFreeSlotsBasedOnBusySlotsJSDate({
      freeStart: s.start_time,
      freeEnd: s.end_time,
      busySlots,
      currentTimeZone,
    });

    if (isEmptyArray(freeSlot)) {
      return;
    }

    freeSlot.forEach((f) => {
      const { start_time, end_time } = f;
      const roundedUpStartTime = RoundToClosestMinuteJSDate(
        start_time,
        roundUpInterval
      );
      const roundedUpEndTime = RoundDownToClosestMinute({
        jsDate: end_time,
        interval: roundDownInterval,
      });

      if (
        differenceInMinutes(roundedUpEndTime, roundedUpStartTime) >=
        durationMinutes
      ) {
        freeSlots = freeSlots.concat({
          start_time: roundedUpStartTime,
          end_time: roundedUpEndTime,
        });
      }
    });
  });

  return freeSlots.map((slot) => {
    return {
      start_time: slot.start_time.toISOString(),
      end_time: slot.end_time.toISOString(),
    };
  });
}

function createFreeSlotsBasedOnBusySlotsJSDate({
  freeStart,
  freeEnd,
  busySlots,
  currentTimeZone,
}) {
  // All time slots come in as utc
  let start = freeStart;
  let end = freeEnd;

  const currentTime = getCurrentTimeInCurrentTimeZone(currentTimeZone);
  if (isAfterMinute(currentTime, start) && isBeforeMinute(currentTime, end)) {
    start = currentTime;
  } else if (isSameOrAfterMinute(currentTime, end)) {
    return [];
  }

  if (isEmptyArray(busySlots)) {
    //Default case where there's no busy slots
    return [{ start_time: start, end_time: end }];
  }

  // Scenerios: top = busy, bottom = free
  let freeSlots = [];
  let shouldSkip = false;

  busySlots.forEach((s, index) => {
    const busyStart = startOfMinute(
      convertToTimeZone(parseISO(s.start), {
        timeZone: currentTimeZone,
      })
    );
    const busyEnd = startOfMinute(
      convertToTimeZone(parseISO(s.end), {
        timeZone: currentTimeZone,
      })
    );

    if (shouldSkip) {
      // console.log('here0');
      //  Do nothing
    } else if (
      isSameOrBeforeMinute(busyStart, start) &&
      isSameOrAfterMinute(busyEnd, end)
    ) {
      // console.log('here1');
      // |------|       |------------|       |--------------------|       |-------------|
      // |------| or          |------|    or     |---------|         or   |----|
      // Skip this time slot altogether
      shouldSkip = true;
    } else if (
      isSameOrBeforeMinute(busyStart, start) &&
      isBeforeMinute(busyEnd, end) &&
      isAfterMinute(busyEnd, start)
    ) {
      // console.log('here2');
      // |-------|            |---------|
      //       |------|   or  |--------------|
      // have to use isAfter otherwise case where busy ends right as free start would fail
      start = busyEnd;
    } else if (
      isSameOrAfterMinute(busyEnd, end) &&
      isAfterMinute(busyStart, start) &&
      isBeforeMinute(busyStart, end)
    ) {
      // console.log('here3');
      //           |---------|              |-----|
      //     |----------|        or    |----------|

      end = busyStart;
    } else if (
      isBeforeMinute(busyStart, end) &&
      isAfterMinute(busyStart, start)
    ) {
      // console.log('here5');
      //         |---|
      //   |-----------------|
      let slot = {
        start_time: start,
        end_time: busyStart,
      };
      freeSlots = freeSlots.concat(slot);

      start = busyEnd;
    } else {
      // console.log('here6');
      // nothing
    }
  });

  if (!shouldSkip) {
    let slot = { start_time: start, end_time: end };
    freeSlots = freeSlots.concat(slot);
  }

  return freeSlots;
}

export function getPersonalLinkIFrame(personalLinkURL) {
  return `<iframe src="${personalLinkURL}" width="800" height="700"></iframe>`;
}

export function getUserAvailabilitySettings({ user }) {
  if (isEmptyObjectOrFalsey(user)) {
    return {};
  }

  if (isUserFromMagicLink({ user })) {
    return getUserConnectedAccountDetails({ user })?.availability_settings;
  }

  return user?.availability_settings;
}

export function isNameOrEmailQuestion(question) {
  return isNameQuestion(question) || isEmailQuestion(question);
}

function isNameQuestion(question) {
  return (
    question?.description === "Name"
  );
}

function isEmailQuestion(question) {
  return (
    question.description === "Email"
  );
}

export function getCriticalAttendees(bookingLink) {
  if (!bookingLink?.critical_attendees) {
    return [];
  }

  return bookingLink.critical_attendees;
}

export function determineIfAttendeeIsCritical({ criticalAttendees, email }) {
  if (isEmptyArrayOrFalsey(criticalAttendees) || !email) {
    return false;
  }

  return criticalAttendees?.includes(lowerCaseAndTrimString(email));
}

export function sortAttendeeListByCritical({ attendeeList = [], criticalAttendees = [] }) {
  if (isEmptyArrayOrFalsey(attendeeList)) {
    return [];
  }
  return immutablySortArray(attendeeList, (a, b) => {
    const firstAttendeeEmail = getObjectEmail(a);
    const secondAttendeeEmail = getObjectEmail(b);
    const isFirstAttendeeCritical = determineIfAttendeeIsCritical({ criticalAttendees, email: firstAttendeeEmail });
    const isSecondAttendeeCritical = determineIfAttendeeIsCritical({ criticalAttendees, email: secondAttendeeEmail });

    /* Attendee A is critical */
    /* Attendee B is not critical */
    /* Sort A before B by returning a negative value */
    if (isFirstAttendeeCritical && !isSecondAttendeeCritical) {
      return -1;
    }

    /* Attendee A is not critical */
    /* Attendee B is critical */
    /* Sort A after B by returning a positive value */
    if (!isFirstAttendeeCritical && isSecondAttendeeCritical) {
      return 1;
    }

    /* For adding new row */
    if (firstAttendeeEmail && !secondAttendeeEmail) {
      return -1;
    }

    if (!firstAttendeeEmail && secondAttendeeEmail) {
      return 1;
    }

    /* Sort by email if both or neither are critical */
    return firstAttendeeEmail.localeCompare(secondAttendeeEmail);
  });
}

export const TEXT_STYLE_OPTIONS = {
  HORIZONTAL: "horizontal",
  BOLD_DATE: "bold-date",
  SHORTEN_DATE: "shorten-date",
  SPACE_BETWEEN_DATES: "space-between-dates",
  SHOW_TIME_ZONE_ON_EACH_LINE: "show-time-zone-on-each-line",
  BREAK_DURATION: "break-duration",
};

// instead of Object.values so we can keep consistent order
export const TEXT_STYLE_OPTIONS_LIST = [
  TEXT_STYLE_OPTIONS.BOLD_DATE,
  TEXT_STYLE_OPTIONS.HORIZONTAL,
  TEXT_STYLE_OPTIONS.SHORTEN_DATE,
  TEXT_STYLE_OPTIONS.SPACE_BETWEEN_DATES,
  TEXT_STYLE_OPTIONS.SHOW_TIME_ZONE_ON_EACH_LINE,
  TEXT_STYLE_OPTIONS.BREAK_DURATION,
];
