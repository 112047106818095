import { isTypeString } from "./typeGuards";

const BASE_API_URL_LOCAL = "http://localhost:3001/app/";
const BASE_API_URL_STAGING = "https://staging.vimcal.com/app/";
const BASE_API_URL_DOGFOOD = "https://dogfood.vimcal.com/app/";
const BASE_API_URL_DEVELOPMENT = "https://dev.vimcal.com/app/";
const BASE_API_URL_PRODUCTION = "https://prod.vimcal.com/app/";
const BASE_API_URL_TESTING = "https://testing.vimcal.com/app/";

const getAppEnvironmentURL = () => {
  if (process.env.REACT_APP_SERVER_BASE_URL) {
    return process.env.REACT_APP_SERVER_BASE_URL;
  }
  return BASE_API_URL_PRODUCTION;
  // return BASE_API_URL_DEVELOPMENT;
  // return BASE_API_URL_DOGFOOD;
  // return BASE_API_URL_STAGING;
  // return BASE_API_URL_TESTING;
  // return BASE_API_URL_LOCAL;
};

const BASE_API_URL = getAppEnvironmentURL();
// const BASE_API_URL = isDogfoodEnv
//   ? BASE_API_URL_PRODUCTION
//   : getAppEnvironmentURL();

export function constructRequestURL(path: string, v2 = false) {
  const version = v2 ? "v2" : "v1";
  // const versionURL = BASE_API_URL[version]
  const updatedBaseAPI = BASE_API_URL.includes("v1")
    ? BASE_API_URL.replace("v1", "")
    : BASE_API_URL; // need to do this for backward compatibility on heroku

  const versionURL = updatedBaseAPI + version ?? "v1";

  return `${versionURL}/${path}`;
}

// Grab base API and add v2 to it
export function constructRequestURLV2(path: string) {
  const updatedBaseAPI = BASE_API_URL.includes("v1")
    ? BASE_API_URL.replace("v1", "")
    : BASE_API_URL; // need to do this for backward compatibility on heroku

  const versionURL = updatedBaseAPI + "v2";

  return `${versionURL}/${path}`;
}

/**
 * Check if a response from our backend is an error response.
 */
export function isErrorResponse<T extends Record<string, unknown>>(response: T): response is T & ErrorResponse {
  try {
    return "error" in response && !!response.error;
  } catch {
    return false;
  }
}

/**
 * The `error` property in our response is sometimes a string and other times a boolean.
 * If it's a boolean, there may be a message in the `message` property.
 */
export function getErrorResponseMessage(response: ErrorResponse) {
  try {
    const { error, message } = response;
    return isTypeString(error) ? error : message;
  } catch {
    return "";
  }
}
